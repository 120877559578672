import { BehaviorSubject, merge, Observable } from "rxjs";
import { takeUntil, distinctUntilChanged, map } from "rxjs/operators";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { ofType } from "../../../../shared/services/message-bus/operators/ofType";
import { Service } from "typedi";
import { IMessageBus } from "../../shared/message-bus/IMessageBus";
import { ITranslationState } from "./ITranslationState";

interface ILocaleChangeData {
  locale?: string;
  translations?: Record<string, string>;
}

interface ILocaleChangeEvent {
  type: string;
  data: ILocaleChangeData;
}

const initialState: ITranslationState = {
  locale: "en_GB",
  translations: {},
  overrides: {},
};

@Service()
export class FrameAttachedTranslationsStore extends BehaviorSubject<ITranslationState> {
  private destroy$: Observable<unknown>;

  constructor(private messageBus: IMessageBus) {
    super({ ...initialState });
    this.destroy$ = this.messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY));
    this.setupSubscriptions();
  }

  private setupSubscriptions(): void {
    const observables = [
      this.createLocaleChangeObservable(),
      this.createUpdateTranslationObservable(),
      this.createConfigChangeObservable(),
    ];

    merge(...observables)
      .pipe(
        map(this.mapEventToState.bind(this)),
        // @ts-ignore we know what the values are
        distinctUntilChanged((prev, cur) => this.areStatesEqual(prev, cur)),
      )
      .subscribe(this.next.bind(this));
  }

  private createLocaleChangeObservable(): Observable<ILocaleChangeEvent> {
    return this.createDistinctObservable(
      PUBLIC_EVENTS.LOCALE_CHANGED,
      (prev, curr) => prev.data.locale === curr.data.locale,
    );
  }

  private createUpdateTranslationObservable(): Observable<ILocaleChangeEvent> {
    return this.createDistinctObservable(
      PUBLIC_EVENTS.UPDATE_TRANSLATION,
      (prev, curr) => JSON.stringify(prev.data) === JSON.stringify(curr.data),
    );
  }

  private createConfigChangeObservable(): Observable<ILocaleChangeEvent> {
    return this.createDistinctObservable(
      PUBLIC_EVENTS.CONFIG_CHANGED,
      (prev, curr) =>
        JSON.stringify(prev.data.translations) ===
        JSON.stringify(curr.data.translations),
    );
  }

  private createDistinctObservable(
    eventType: string,
    compareFunc: (
      prev: ILocaleChangeEvent,
      curr: ILocaleChangeEvent,
    ) => boolean,
  ): Observable<ILocaleChangeEvent> {
    return this.messageBus.pipe(
      ofType(eventType),
      takeUntil(this.destroy$),
      distinctUntilChanged(compareFunc),
    );
  }

  private mapEventToState(event: ILocaleChangeEvent): ITranslationState {
    const currentState = this.getValue();
    switch (event.type) {
      case PUBLIC_EVENTS.LOCALE_CHANGED:
        return {
          ...currentState,
          locale: event.data.locale || currentState.locale,
          translations: event.data.translations || {},
        };
      case PUBLIC_EVENTS.UPDATE_TRANSLATION:
        return {
          ...currentState,
          overrides: event.data as Record<string, string>,
        };
      case PUBLIC_EVENTS.CONFIG_CHANGED:
        return {
          ...currentState,
          overrides: event.data.translations || {},
        };
      default:
        return currentState;
    }
  }

  private areStatesEqual(
    prev: ITranslationState,
    curr: ITranslationState,
  ): boolean {
    return JSON.stringify(prev) === JSON.stringify(curr);
  }
}
