import { IScriptOriginTag } from "./interfaces/IScriptOriginTag";
import { OriginTag, ScriptOriginTagger } from "./ScriptOriginTagger";

export class ScriptOriginLookup {
  private static origin: IScriptOriginTag;

  /**
   * Returns the known origin, or falls-back to the current window origin if not known.
   *
   * This fallback will generally be utilised by the control frame. We specifically check
   * for UNKNOWN and empty, because we don't want to override UNKNOWN entries with values.
   *
   */
  static getOrigin() {
    if (
      !this.origin ||
      (this.origin.tag === OriginTag.UNKNOWN && this.origin.url === "")
    ) {
      return new ScriptOriginTagger().getTag(window.location.host);
    }

    return this.origin;
  }

  static setOrigin(origin: IScriptOriginTag) {
    this.origin = origin;
  }
}
