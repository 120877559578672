import { Subscription } from "rxjs";
import { iinLookup } from "@trustpayments/ts-iin-lookup";
import { BrandDetailsType } from "@trustpayments/ts-iin-lookup/dist/types";
import { luhnCheck } from "@trustpayments/ts-luhn-check";
import { Container, Service } from "typedi";
import { StCodec } from "../../services/st-codec/StCodec";
import { FormState } from "../../models/constants/FormState";
import { ICard } from "../../models/ICard";
import { IErrorData } from "../../models/IErrorData";
import { IFormFieldState } from "../../models/IFormFieldState";
import { IMessageBusEvent } from "../../models/IMessageBusEvent";
import { IMessageBusValidateField } from "../../models/IMessageBusValidateField";
import { IValidation } from "../../models/IValidation";
import { Frame } from "../frame/Frame";
import {
  VALIDATION_ERROR,
  VALIDATION_ERROR_FIELD_IS_REQUIRED,
  VALIDATION_ERROR_PATTERN_MISMATCH,
} from "../../models/constants/Translations";
import { MESSAGE_BUS } from "../message-bus/MessageBus";
import { CARD_NUMBER_INPUT } from "../../models/constants/Selectors";
import { Utils } from "../utils/Utils";
import { IMessageBus } from "../message-bus/IMessageBus";
import {
  MESSAGE_BUS_TOKEN,
  TRANSLATOR_TOKEN,
  TRANSLATION_CHANGE_TRACKER_TOKEN,
} from "../../../../shared/dependency-injection/InjectionTokens";
import { ITranslator } from "../translator/ITranslator";
import { IFormFieldsValidity } from "../../models/IFormFieldsValidity";
import { EventScope } from "../../models/constants/EventScope";
import { ITranslationChangeTracker } from "../translator/ITranslationChangeTracker";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { ofTypeList } from "../../../../shared/services/message-bus/operators/ofTypeList";

@Service()
export class Validation {
  static ERROR_FIELD_CLASS = "error-field";

  static clearNonDigitsChars(value: string): string {
    return value.replace(Validation.escapeDigitsRegexp, Validation.clearValue);
  }

  static getValidationMessage(
    state: ValidityState,
    isInputOptional?: boolean,
  ): string {
    const {
      patternMismatch: isPatternMismatch,
      valid: isValid,
      valueMissing: isValueMissing,
    } = state;

    if (isValid) {
      return "";
    }

    if (isValueMissing) {
      return isInputOptional ? "" : VALIDATION_ERROR_FIELD_IS_REQUIRED;
    }

    if (isPatternMismatch) {
      return VALIDATION_ERROR_PATTERN_MISMATCH;
    }
    return VALIDATION_ERROR;
  }

  static isCharNumber(event: KeyboardEvent): boolean {
    const key: string = event.key;
    const regex = new RegExp(Validation.escapeDigitsRegexp);
    return regex.test(key);
  }

  static isEnter(event: KeyboardEvent): boolean {
    if (event) {
      const keyCode: number = event.keyCode;
      return keyCode === Validation.enterKeyCode;
    } else {
      return false;
    }
  }

  static setCustomValidationError(
    errorContent: string,
    inputElement: HTMLInputElement,
  ): void {
    inputElement.setCustomValidity(errorContent);
  }

  static addErrorContainer(
    inputElement: HTMLInputElement,
    inputTarget: InsertPosition,
    errorContent: string,
  ): void {
    inputElement.insertAdjacentHTML(inputTarget, errorContent);
  }

  static resetValidationProperties(input: HTMLInputElement): void {
    input.setCustomValidity(Validation.clearValue);
    input.classList.remove(Validation.ERROR_FIELD_CLASS);
    input.nextSibling.textContent = Validation.clearValue;
  }

  static returnInputAndErrorContainerPair(item: HTMLInputElement): {
    inputElement: HTMLInputElement;
    messageElement: HTMLElement;
  } {
    return {
      inputElement: document.getElementById(item.id) as HTMLInputElement,
      messageElement: document.getElementById(item.id)
        .nextSibling as HTMLElement,
    };
  }

  private static backspaceKeyCode = 8;
  private static cardNumberDefaultLength = 16;
  private static cardNumberFieldName = "pan";
  private static clearValue = "";
  private static deleteKeyCode = 46;
  private static enterKeyCode = 13;
  private static errorClass = "error";
  private static escapeDigitsRegexp = /[^\d]/g;
  private static expiryDateFieldName = "expirydate";
  private static idParamName = "id";
  private static matchChars = /[^\d]/g;
  private static matchDigits = /^[0-9]*$/;
  private static merchantExtraFieldsPrefix = "billing";
  private static securityCodeFieldName = "securitycode";
  private static backendErrorFieldsNames = {
    cardNumber: "pan",
    expirationDate: "expirydate",
    securityCode: "securitycode",
  };

  private static setValidateEvent(
    errordata: string,
    event: IMessageBusEvent,
  ): IMessageBusEvent {
    switch (errordata) {
      case Validation.backendErrorFieldsNames.cardNumber:
        event.type = MESSAGE_BUS.EVENTS.VALIDATE_CARD_NUMBER_FIELD;
        break;
      case Validation.backendErrorFieldsNames.expirationDate:
        event.type = MESSAGE_BUS.EVENTS.VALIDATE_EXPIRATION_DATE_FIELD;
        break;
      case Validation.backendErrorFieldsNames.securityCode:
        event.type = MESSAGE_BUS.EVENTS.VALIDATE_SECURITY_CODE_FIELD;
        break;
    }
    return event;
  }

  private static isFormValid(
    formFields: {
      cardNumber: IFormFieldState;
      expirationDate: IFormFieldState;
      securityCode: IFormFieldState;
    },
    fieldsToSubmit: string[],
  ): boolean {
    const isPanValid: boolean = fieldsToSubmit.includes(
      Validation.cardNumberFieldName,
    )
      ? formFields.cardNumber.isValid
      : true;
    const isExpiryDateValid: boolean = fieldsToSubmit.includes(
      Validation.expiryDateFieldName,
    )
      ? formFields.expirationDate.isValid
      : true;
    const isSecurityCodeValid: boolean = fieldsToSubmit.includes(
      Validation.securityCodeFieldName,
    )
      ? formFields.securityCode.isValid
      : true;
    return isPanValid && isExpiryDateValid && isSecurityCodeValid;
  }

  cardDetails: BrandDetailsType;
  cardNumberValue: string;
  expirationDateValue: string;
  securityCodeValue: string;
  validation: IValidation;
  private card: ICard;
  private currentKeyCode: number;
  private formValidity: boolean;
  private isPaymentReady: boolean;
  private matchDigitsRegexp: RegExp;
  private selectionRangeEnd: number;
  private selectionRangeStart: number;
  private messageBus: IMessageBus;
  private frame: Frame;
  private translationUpdateService: ITranslationChangeTracker;
  private getTranslationSubscription: Subscription;
  private setErrorTranslationSubscription: Subscription;

  constructor(
    private translator: ITranslator = Container.get(TRANSLATOR_TOKEN),
  ) {
    this.messageBus = Container.get(MESSAGE_BUS_TOKEN);
    this.frame = Container.get(Frame);
    this.translationUpdateService = Container.get(
      TRANSLATION_CHANGE_TRACKER_TOKEN,
    );
    this.init();
  }

  backendValidation(
    inputElement: HTMLInputElement,
    messageElement: HTMLElement,
    event: string,
  ): void {
    this.messageBus.subscribeType(event, (data: IMessageBusValidateField) => {
      this.setError(inputElement, messageElement, data);
    });
  }

  blockForm(state: FormState): void {
    const messageBusEvent: IMessageBusEvent = {
      data: state,
      type: MESSAGE_BUS.EVENTS_PUBLIC.BLOCK_FORM,
    };
    this.messageBus.publish(messageBusEvent, EventScope.EXPOSE_TO_MERCHANT);
  }

  callSubmitEvent(): void {
    const messageBusEvent: IMessageBusEvent = {
      type: MESSAGE_BUS.EVENTS_PUBLIC.CALL_SUBMIT_EVENT,
    };
    this.messageBus.publish(messageBusEvent, EventScope.EXPOSE_TO_MERCHANT);
  }

  formValidation(
    dataInJwt: boolean,
    fieldsToSubmit: string[],
    formFields: {
      cardNumber: IFormFieldState;
      expirationDate: IFormFieldState;
      securityCode: IFormFieldState;
    },
    paymentReady: boolean,
  ): { card: ICard; isValid: boolean } {
    this.setValidationResult(
      dataInJwt,
      fieldsToSubmit,
      formFields,
      paymentReady,
    );
    const isFormReadyToSubmit: boolean = this.isFormReadyToSubmit();
    if (isFormReadyToSubmit) {
      this.blockForm(FormState.BLOCKED);
    }
    return {
      card: this.card,
      isValid: isFormReadyToSubmit,
    };
  }

  getErrorData(errorData: IErrorData): {
    field: unknown;
    errormessage: unknown;
  } {
    // @ts-ignore
    const { errordata, errormessage } = StCodec.getErrorData(errorData);
    const validationEvent: IMessageBusEvent = {
      data: { field: errordata[0], message: errormessage },
      type: Validation.clearValue,
    };

    this.broadcastFormFieldError(errordata[0], validationEvent);

    if (
      errordata.find((element: string) =>
        element.includes(Validation.merchantExtraFieldsPrefix),
      )
    ) {
      validationEvent.type = MESSAGE_BUS.EVENTS.VALIDATE_MERCHANT_FIELD;
      this.messageBus.publish(validationEvent);
    }

    return { field: errordata[0], errormessage };
  }

  keepCursorsPosition(element: HTMLInputElement): void {
    const cursorSingleSkip = 1;
    const cursorDoubleSkip = 2;
    const dateSlash = "/";
    const end: number = this.selectionRangeEnd;
    const start: number = this.selectionRangeStart;
    const noSelection = 0;
    const selectionLength: number = start - end;
    const spaceInPan = " ";
    const lengthFormatted: number = element.value.length;
    const isLastCharSlash: boolean =
      element.value.charAt(lengthFormatted - cursorDoubleSkip) === dateSlash;

    if (this.isPressedKeyDelete()) {
      element.setSelectionRange(start, end);
    } else if (this.isPressedKeyBackspace()) {
      element.setSelectionRange(
        start - cursorSingleSkip,
        end - cursorSingleSkip,
      );
    } else if (
      isLastCharSlash ||
      (element.value.charAt(end) === spaceInPan &&
        selectionLength === noSelection)
    ) {
      element.setSelectionRange(
        start + cursorDoubleSkip,
        end + cursorDoubleSkip,
      );
    } else if (selectionLength === noSelection) {
      element.setSelectionRange(
        start + cursorSingleSkip,
        end + cursorSingleSkip,
      );
    } else {
      element.setSelectionRange(
        start + cursorSingleSkip,
        start + cursorSingleSkip,
      );
    }
  }

  luhnCheck(
    field: HTMLInputElement,
    input: HTMLInputElement,
    message: HTMLDivElement,
  ): void {
    const { value } = input;

    let hasValidCardLength = false;
    let isLuhnPassed = false;
    if (value) {
      const cleanCardNumber = value.replace(/\s+/g, "");
      const cardDetails = iinLookup.lookup(cleanCardNumber);

      hasValidCardLength =
        cardDetails &&
        cardDetails.type &&
        cardDetails.length?.includes(cleanCardNumber.length);

      if (hasValidCardLength) {
        isLuhnPassed = luhnCheck(cleanCardNumber);
      }
    }

    if (!hasValidCardLength || !isLuhnPassed) {
      Validation.setCustomValidationError(
        VALIDATION_ERROR_PATTERN_MISMATCH,
        field,
      );
      this.validate(input, message, false, VALIDATION_ERROR_PATTERN_MISMATCH);
    } else {
      Validation.setCustomValidationError(Validation.clearValue, field);
    }
  }

  limitLength(value: string, length: number): string {
    return value ? value.substring(0, length) : Validation.clearValue;
  }

  removeError(element: HTMLInputElement, errorContainer: HTMLElement): void {
    element.classList.remove(Validation.errorClass);
    errorContainer.textContent = Validation.clearValue;
  }

  setError(
    inputElement: HTMLInputElement,
    messageElement: HTMLElement,
    data: IMessageBusValidateField,
  ): void {
    const { message } = data;
    if (
      message &&
      messageElement &&
      messageElement.innerText !== VALIDATION_ERROR_PATTERN_MISMATCH
    ) {
      messageElement.innerText = this.translator.translate(message);
      if (this.setErrorTranslationSubscription) {
        this.setErrorTranslationSubscription.unsubscribe();
      }
      this.setErrorTranslationSubscription = this.translationUpdateService
        .updateRequired()
        .subscribe(() => {
          messageElement.innerText = this.translator.translate(message);
        });
      inputElement.classList.add(Validation.ERROR_FIELD_CLASS);
      messageElement.style.visibility = "visible";
      inputElement.setCustomValidity(message);
    } else {
      inputElement.classList.remove(Validation.ERROR_FIELD_CLASS);
      messageElement.style.visibility = "hidden";
      inputElement.setCustomValidity(message);
    }
  }

  setOnKeyDownProperties(
    element: HTMLInputElement,
    event: KeyboardEvent,
  ): void {
    this.currentKeyCode = event.keyCode;
    this.selectionRangeStart = element.selectionStart;
    this.selectionRangeEnd = element.selectionEnd;
  }

  setFormValidity(state: IFormFieldsValidity): void {
    const validationEvent: IMessageBusEvent = {
      data: { ...state },
      type: MESSAGE_BUS.EVENTS.VALIDATE_FORM,
    };
    this.messageBus.publish(validationEvent);
  }

  validate(
    inputElement: HTMLInputElement,
    messageElement: HTMLElement,
    isInputOptional?: boolean,
    customErrorMessage?: string,
  ): void {
    this.toggleErrorClass(inputElement, isInputOptional);
    this.toggleErrorContainer(inputElement, messageElement);
    this.setMessage(
      inputElement,
      messageElement,
      customErrorMessage,
      isInputOptional,
    );
  }

  init(): void {
    this.matchDigitsRegexp = new RegExp(Validation.matchDigits);
  }

  removeNonDigits(value: string): string {
    if (value) {
      return value.replace(Validation.matchChars, Validation.clearValue);
    }
    return "";
  }

  getCardDetails(cardNumber: string = Validation.clearValue): BrandDetailsType {
    return iinLookup.lookup(cardNumber);
  }

  cardNumber(value: string): void {
    this.cardNumberValue = this.removeNonDigits(value);
    this.cardDetails = this.getCardDetails(this.cardNumberValue);
    const length = this.cardDetails.type
      ? Utils.getLastElementOfArray(this.cardDetails.length)
      : Validation.cardNumberDefaultLength;
    this.cardNumberValue = this.limitLength(this.cardNumberValue, length);
  }

  expirationDate(value: string): void {
    this.expirationDateValue = value
      ? this.removeNonDigits(value)
      : Validation.clearValue;
  }

  securityCode(value: string, length: number): void {
    this.securityCodeValue = value
      ? this.limitLength(this.removeNonDigits(value), length)
      : Validation.clearValue;
  }

  private broadcastFormFieldError(
    errordata: string,
    event: IMessageBusEvent,
  ): void {
    this.messageBus.publish(Validation.setValidateEvent(errordata, event));
  }

  private getTranslation(
    inputElement: HTMLInputElement,
    isCardNumberInput: boolean,
    validityState: string,
    messageElement?: HTMLElement,
    customErrorMessage?: string,
  ): string {
    if (messageElement && customErrorMessage && !isCardNumberInput) {
      return this.translator.translate(customErrorMessage);
    } else if (
      messageElement &&
      inputElement.value &&
      isCardNumberInput &&
      !inputElement.validity.valid
    ) {
      return this.translator.translate(VALIDATION_ERROR_PATTERN_MISMATCH);
    } else {
      return this.translator.translate(validityState);
    }
  }

  private isFormReadyToSubmit(): boolean {
    return this.isPaymentReady && this.formValidity;
  }

  private isPressedKeyBackspace(): boolean {
    return this.currentKeyCode === Validation.backspaceKeyCode;
  }

  private isPressedKeyDelete(): boolean {
    return this.currentKeyCode === Validation.deleteKeyCode;
  }

  private setMessage(
    inputElement: HTMLInputElement,
    messageElement: HTMLElement,
    customErrorMessage?: string,
    isInputOptional?: boolean,
  ): void {
    const isCardNumberInput: boolean =
      inputElement.getAttribute(Validation.idParamName) === CARD_NUMBER_INPUT;
    const validityState = Validation.getValidationMessage(
      inputElement.validity,
      isInputOptional,
    );
    messageElement.innerText = this.getTranslation(
      inputElement,
      isCardNumberInput,
      validityState,
      messageElement,
      customErrorMessage,
    );
    if (this.getTranslationSubscription) {
      this.getTranslationSubscription.unsubscribe();
    }
    this.getTranslationSubscription = this.translationUpdateService
      .updateRequired()
      .subscribe(() => {
        messageElement.innerText = this.getTranslation(
          inputElement,
          isCardNumberInput,
          validityState,
          messageElement,
          customErrorMessage,
        );
      });
  }

  private toggleErrorContainer(
    inputElement: HTMLInputElement,
    messageElement: HTMLElement,
  ): void {
    inputElement.validity.valid
      ? (messageElement.style.visibility = "hidden")
      : (messageElement.style.visibility = "visible");
  }

  private toggleErrorClass(
    inputElement: HTMLInputElement | null, // Explicitly handle null cases
    isInputOptional: boolean = false, // Default value for isInputOptional
  ): void {
    if (!inputElement) {
      // Early return if inputElement is null or undefined
      return;
    }

    const isEmpty = inputElement.value.length === 0;
    const isValid = inputElement.validity.valid;

    // If the input is optional and empty, or the input is valid, remove the error class
    const shouldRemoveError = (isInputOptional && isEmpty) || isValid;

    // Toggle the error class based on the shouldRemoveError flag
    inputElement.classList.toggle(
      Validation.ERROR_FIELD_CLASS,
      !shouldRemoveError,
    );
  }

  private setValidationResult(
    dataInJwt: boolean,
    fieldsToSubmit: string[],
    formFields: {
      cardNumber: IFormFieldState;
      expirationDate: IFormFieldState;
      securityCode: IFormFieldState;
    },
    paymentReady: boolean,
  ): void {
    if (dataInJwt) {
      this.formValidity = true;
      this.isPaymentReady = true;
    } else {
      this.formValidity = Validation.isFormValid(formFields, fieldsToSubmit);
      this.isPaymentReady = paymentReady;
      this.card = {
        expirydate: formFields.expirationDate.value,
        pan: formFields.cardNumber.value,
        securitycode: formFields.securityCode.value,
      };
    }
  }
  registerValidationListeners(
    inputElement: HTMLInputElement,
    messageElement: HTMLDivElement,
  ) {
    const eventsToWatch: string[] = [
      PUBLIC_EVENTS.AUTOCOMPLETE_EXPIRATION_DATE,
      PUBLIC_EVENTS.AUTOCOMPLETE_CARD_NUMBER,
      PUBLIC_EVENTS.AUTOCOMPLETE_SECURITY_CODE,
    ];

    this.messageBus.pipe(ofTypeList(eventsToWatch)).subscribe(() => {
      this.validate(inputElement, messageElement);
    });
  }
}
