import { Observable, of, first, fromEvent, mapTo } from "rxjs";
import { IScriptParams } from "../../models/IScriptParams";
import { DomMethods } from "./DomMethods";

export class DomScriptUtils {
  private static srcAttribute = "src";
  private static scriptMarkup = "script";

  static insertScript(
    target: string,
    params: IScriptParams,
  ): Observable<HTMLScriptElement> {
    const loaded: HTMLScriptElement = this.isScriptLoaded(params);

    if (loaded) {
      return of(loaded).pipe(first());
    }

    let targetElement: Element = document.getElementsByTagName(target)[0];
    if (!targetElement) {
      targetElement = document.getElementById(target);
    }
    // @ts-expect-error TypeScript doesn't allow you to assign known interfaces to dictionaries
    const script: HTMLScriptElement = this.setMarkupAttributes(
      DomScriptUtils.scriptMarkup,
      // @ts-expect-error TypeScript doesn't allow you to assign known interfaces to dictionaries
      params,
    );
    targetElement.appendChild(script);
    script.addEventListener("error", () => DomMethods.removeElement(script));

    return fromEvent(script, "load").pipe(mapTo(script), first());
  }

  private static isScriptLoaded(
    params: IScriptParams,
  ): HTMLScriptElement | null {
    const { src, id } = params;
    const scriptBySrc: HTMLScriptElement | null =
      document.querySelector<HTMLScriptElement>(
        `${DomScriptUtils.scriptMarkup}[${DomScriptUtils.srcAttribute}="${src}"]`,
      );
    const scriptById: HTMLScriptElement | null = document.getElementById(
      id,
    ) as HTMLScriptElement;
    return scriptById || scriptBySrc;
  }

  private static setMarkupAttributes(
    target: string,
    params: Record<string, string>,
  ): HTMLElement {
    const element: HTMLElement = document.createElement(target);
    Object.keys(params).forEach((param: string) => {
      element.setAttribute(param, params[param]);
    });
    return element;
  }
}
