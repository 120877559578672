import Joi from "joi";

export const GOOGLE_PAY_CARD_SCHEMA: Joi.ObjectSchema = Joi.object().keys({
  allowPrepaidCards: Joi.boolean(),
  allowCreditCards: Joi.boolean(),
  allowedAuthMethods: Joi.array()
    .items(Joi.string(), Joi.string())
    .min(1)
    .max(2)
    .unique()
    .required(),
  allowedCardNetworks: Joi.array().items(Joi.string()).required(),
  assuranceDetailsRequired: Joi.boolean(),
  billingAddressParameters: Joi.object().keys({
    format: Joi.string(),
    phoneNumberRequired: Joi.boolean(),
  }),
  billingAddressRequired: Joi.boolean(),
});

export const AUTHJWT_ERROR_MSG =
  "Google Pay Error: merchantOrigin is required when setting authJwt";

export const GOOGLE_PAY_PAYMENT_REQUEST_SCHEMA: Joi.ObjectSchema =
  Joi.object().keys({
    allowedPaymentMethods: Joi.array().items(
      Joi.object()
        .keys({
          parameters: GOOGLE_PAY_CARD_SCHEMA,
          tokenizationSpecification: Joi.object().keys({
            parameters: {
              gateway: Joi.string(),
              gatewayMerchantId: Joi.string(),
            },
            type: Joi.string(),
          }),
          type: Joi.string().required(),
        })
        .min(1)
        .required(),
    ),
    apiVersion: Joi.number().min(2).max(2).integer().required(),
    apiVersionMinor: Joi.number().min(0).max(0).integer().required(),
    callbackIntents: Joi.array().items(Joi.string()),
    paymentDataCallbacks: Joi.object().keys({
      onPaymentAuthorized: Joi.function(),
      onPaymentDataChanged: Joi.function(),
    }),
    emailRequired: Joi.boolean(),
    merchantInfo: Joi.object()
      .keys({
        merchantId: Joi.string(),
        merchantName: Joi.string(),
        merchantOrigin: Joi.string().allow("").optional(),
        authJwt: Joi.string().allow("").optional(),
      })
      .with("authJwt", "merchantOrigin")
      .messages({
        "any.only": "{{#label}} is required when setting authJwt",
        "object.with": AUTHJWT_ERROR_MSG,
      })
      .required(),
    environment: Joi.string().valid("TEST", "PRODUCTION"),
    shippingAddressParameters: Joi.object().keys({
      allowedCountryCodes: Joi.array().items(Joi.string()),
      phoneNumberRequired: Joi.boolean(),
    }),
    shippingAddressRequired: Joi.boolean(),
    shippingOptionParameters: Joi.object().keys({
      defaultSelectedOptionId: Joi.string(),
      shippingOptions: Joi.array().items(
        Joi.object().keys({
          description: Joi.string(),
          id: Joi.string(),
          label: Joi.string(),
        }),
      ),
    }),
    shippingOptionRequired: Joi.boolean(),
    transactionInfo: Joi.object()
      .keys({
        checkoutOption: Joi.string(),
        countryCode: Joi.string(),
        currencyCode: Joi.string(),
        displayItems: Joi.array().items(
          Joi.object().keys({
            label: Joi.string(),
            price: Joi.string(),
            status: Joi.string(),
            type: Joi.string(),
          }),
        ),
        totalPriceLabel: Joi.string(),
        totalPriceStatus: Joi.string(),
        totalPrice: Joi.string(),
        transactionId: Joi.string(),
      })
      .required(),
  });
