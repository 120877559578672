import { Service } from "typedi";
import {
  BehaviorSubject,
  catchError,
  distinctUntilKeyChanged,
  firstValueFrom,
  from,
  NEVER,
  Observable,
  of,
} from "rxjs";
import { filter, mapTo, switchMap, tap } from "rxjs/operators";
import { IClickToPayAdapter } from "../interfaces/IClickToPayClientAdapter";
import { DigitalTerminal } from "../../digital-terminal/DigitalTerminal";
import { IInitPaymentMethod } from "../../../../application/core/services/payments/events/IInitPaymentMethod";
import { IMessageBus } from "../../../../application/core/shared/message-bus/IMessageBus";
import { PUBLIC_EVENTS } from "../../../../application/core/models/constants/EventTypes";
import { CLICK_TO_PAY_PAYMENT_METHOD_NAME } from "../../models/ClickToPayPaymentMethodName";
import { EventScope } from "../../../../application/core/models/constants/EventScope";
import { IMessageBusEvent } from "../../../../application/core/models/IMessageBusEvent";
import { IFrameQueryingService } from "../../../../shared/services/message-bus/interfaces/IFrameQueryingService";
import { IIdentificationData } from "../../digital-terminal/interfaces/IIdentificationData";
import { SrcNameFinder } from "../../digital-terminal/SrcNameFinder";
import { SrcName } from "../../digital-terminal/SrcName";
import { IIdentificationResult } from "../../digital-terminal/interfaces/IIdentificationResult";
import { IInitialCheckoutData } from "../../digital-terminal/interfaces/IInitialCheckoutData";
import { CardListGenerator } from "../../card-list/CardListGenerator";
import { DcfActionCode, ICheckoutResponse } from "../../digital-terminal/ISrc";
import { untilDestroy } from "../../../../shared/services/message-bus/operators/untilDestroy";
import { IAggregatedProfiles } from "../../digital-terminal/interfaces/IAggregatedProfiles";
import { IUpdateView } from "../interfaces/IUpdateView";
import { IHPPClickToPayAdapterInitParams } from "./IHPPClickToPayAdapterInitParams";
import { HPPUserIdentificationService } from "./HPPUserIdentificationService";
import { HPPCheckoutDataProvider } from "./HPPCheckoutDataProvider";
import { HPPUpdateViewCallback } from "./HPPUpdateViewCallback";
import { HPPFormFieldName } from "./HPPFormFieldName";
import "./hpp-adapter.scss";

@Service()
export class HPPClickToPayAdapter
  implements
    IClickToPayAdapter<IHPPClickToPayAdapterInitParams, HPPClickToPayAdapter>
{
  private initParams: IHPPClickToPayAdapterInitParams;
  private dcfContainer: HTMLDivElement;

  constructor(
    private digitalTerminal: DigitalTerminal,
    private messageBus: IMessageBus,
    private frameQueryingService: IFrameQueryingService,
    private userIdentificationService: HPPUserIdentificationService,
    private srcNameFinder: SrcNameFinder,
    private cardListGenerator: CardListGenerator,
    private hppCheckoutDataProvider: HPPCheckoutDataProvider,
    private hppUpdateViewCallback: HPPUpdateViewCallback,
  ) {}

  init(
    initParams: IHPPClickToPayAdapterInitParams,
  ): Promise<HPPClickToPayAdapter> {
    this.initParams = initParams;
    this.hppUpdateViewCallback.init(initParams.onUpdateView);
    this.hppUpdateViewCallback
      .getUpdateViewState()
      .pipe(
        distinctUntilKeyChanged<IUpdateView>("displayCardForm"),
        untilDestroy<IUpdateView>(this.messageBus),
      )
      .subscribe((updateData) => this.disableHiddenFormFields(updateData));
    this.startPaymentMethodInit(initParams);
    return this.completePaymentMethodInit(initParams);
  }

  isRecognized(): Promise<boolean> {
    return firstValueFrom(this.digitalTerminal.isRecognized());
  }

  identifyUser(
    identificationData?: IIdentificationData,
  ): Promise<IIdentificationResult> {
    this.userIdentificationService.setInitParams(this.initParams);
    return firstValueFrom(
      this.digitalTerminal.identifyUser(
        this.userIdentificationService,
        identificationData,
      ),
    );
  }

  showCardList(): Promise<IAggregatedProfiles> {
    return firstValueFrom(
      this.digitalTerminal.getSrcProfiles().pipe(
        tap((cardList) => {
          this.cardListGenerator.displayCards(
            this.initParams.formId,
            this.initParams.cardListContainerId,
            cardList.aggregatedCards,
          );
          this.cardListGenerator.displayUserInformation(
            this.initParams.cardListContainerId,
            cardList.srcProfiles,
          );
        }),
      ),
    );
  }

  getSrcName(pan: string): Promise<SrcName | null> {
    return firstValueFrom(this.srcNameFinder.findSrcNameByPan(pan));
  }

  private startPaymentMethodInit(initParams: IHPPClickToPayAdapterInitParams) {
    this.messageBus.publish<
      IInitPaymentMethod<IHPPClickToPayAdapterInitParams>
    >(
      {
        type: PUBLIC_EVENTS.INIT_PAYMENT_METHOD,
        data: {
          name: CLICK_TO_PAY_PAYMENT_METHOD_NAME,
          config: initParams,
        },
      },
      EventScope.THIS_FRAME,
    );
  }

  private completePaymentMethodInit(data): Promise<HPPClickToPayAdapter> {
    const initialized = new BehaviorSubject<boolean>(false);

    this.frameQueryingService.whenReceive(
      PUBLIC_EVENTS.CLICK_TO_PAY_INIT,
      (event: IMessageBusEvent<unknown>) => {
        return this.initAdapter(
          event.data as IHPPClickToPayAdapterInitParams,
        ).pipe(tap(() => initialized.next(true)));
      },
    );

    // when adapter is initialized return reference to it
    return firstValueFrom(
      initialized.asObservable().pipe(filter(Boolean), mapTo(this)),
    );
  }

  private initAdapter(
    initParams: IHPPClickToPayAdapterInitParams,
  ): Observable<void> {
    return this.digitalTerminal.init(initParams).pipe(
      tap(() => {
        this.hppCheckoutDataProvider
          .getCheckoutData(initParams.formId)
          .subscribe((data) => this.checkout(data));
      }),
    );
  }

  private checkout(capturedCheckoutData: IInitialCheckoutData) {
    const checkoutData: IInitialCheckoutData = {
      ...capturedCheckoutData,
      dpaTransactionOptions: this.initParams.dpaTransactionOptions,
    };

    const preventUnfinishedCheckoutPropagation = (
      response: ICheckoutResponse,
    ) => {
      switch (response.dcfActionCode) {
        case DcfActionCode.SWITCH_CONSUMER:
        case DcfActionCode.ADD_CARD:
        case DcfActionCode.CHANGE_CARD:
          return NEVER;
        default:
          return of(response);
      }
    };

    this.messageBus.publish({
      type: PUBLIC_EVENTS.START_PAYMENT_METHOD,
      data: {
        name: CLICK_TO_PAY_PAYMENT_METHOD_NAME,
      },
    });

    checkoutData.windowRef = this.getDcfWindowRef();

    this.frameQueryingService.whenReceive(
      PUBLIC_EVENTS.CLICK_TO_PAY_CHECKOUT,
      () =>
        this.digitalTerminal.checkout(checkoutData).pipe(
          tap((response) => {
            return this.initParams?.onCheckout?.call(null, response);
          }),
          switchMap((response) => this.handleCheckoutResponse(response)),
          switchMap(preventUnfinishedCheckoutPropagation),
          catchError((err) => {
            if (err.name === SrcName.MASTERCARD) {
              const dcfErrorMsgDiv = document.createElement("div");
              dcfErrorMsgDiv.classList.add("st-dcf__error");
              dcfErrorMsgDiv.innerHTML =
                "<p>Something went wrong</p><p>Please click the below button to try an alternative method.</p><br>";

              const returnHomeBtn = document.createElement("button");
              returnHomeBtn.innerHTML = "Go Back";
              returnHomeBtn.addEventListener("click", () => {
                this.handleCheckoutResponse({
                  checkoutResponse: "Error",
                  dcfActionCode: DcfActionCode.ERROR,
                  unbindAppInstance: true,
                  idToken: "-------",
                  srcName: SrcName.MASTERCARD,
                });
              });
              dcfErrorMsgDiv.appendChild(returnHomeBtn);

              const dcfIframe =
                this.dcfContainer.getElementsByClassName("st-dcf__frame");
              if (dcfIframe) {
                for (let el = 0; el < dcfIframe.length; el++) {
                  this.dcfContainer.removeChild(dcfIframe[el]);
                }
              }

              this.dcfContainer.appendChild(dcfErrorMsgDiv);
              return NEVER;
            }

            if (err?.error?.reason === "CARD_MISSING") {
              this.handleCheckoutResponse({
                checkoutResponse: "Error",
                dcfActionCode: DcfActionCode.ERROR,
                unbindAppInstance: true,
                idToken: "-------",
                srcName: SrcName.VISA,
              });
            }

            throw err;
          }),
        ),
    );
  }

  private handleCheckoutResponse(
    response: ICheckoutResponse,
  ): Observable<ICheckoutResponse> {
    const formElement: HTMLFormElement = document.querySelector(
      `form#${this.initParams.formId}`,
    );
    const submitList = formElement.querySelectorAll('[type="submit"]');
    submitList.forEach(function (node, index) {
      node.removeAttribute("disabled");
      node.classList.remove("st-form__button--disabled");
    });
    this.dcfContainer.remove();

    if (
      response.dcfActionCode === DcfActionCode.CHANGE_CARD ||
      response.dcfActionCode === DcfActionCode.ADD_CARD
    ) {
      const result = response.idToken?.length
        ? from(this.showCardList()).pipe(mapTo(response))
        : of(response);

      return result.pipe(
        tap(() => {
          if (response.dcfActionCode === DcfActionCode.ADD_CARD) {
            this.cardListGenerator.openNewCardForm();
          }
          if (response.dcfActionCode === DcfActionCode.CHANGE_CARD) {
            if (response.idToken == null) {
              // When the token is null, it means this action only happens
              // when we select EDIT on the VISA screen as a new customer.
              // Doing this check avoids causing issues when we're signed in
              // and want to swap cards.
              this.hppUpdateViewCallback.callUpdateViewCallback({
                displayCardForm: true,
                displaySubmitButton: true,
                clearCardFormContent: true,
                registerCheckboxState: false,
              });
            }
          }
        }),
      );
    }

    if (response.dcfActionCode === DcfActionCode.ERROR) {
      this.cardListGenerator.reset();
      this.cardListGenerator.hideForm();
      this.hppUpdateViewCallback.callUpdateViewCallback({
        displayCardForm: true,
        displaySubmitButton: true,
      });
      return this.digitalTerminal.unbindAppInstance().pipe(
        tap(() => this.cardListGenerator.hideForm()),
        mapTo(response),
      );
    }

    if (response.dcfActionCode === DcfActionCode.CANCEL) {
      if (response.idToken?.length) {
        return response.idToken?.length
          ? from(this.showCardList()).pipe(mapTo(response))
          : of(response);
      } else {
        //To reset the card input fields and register card with checkbox in the ctp page

        this.hppUpdateViewCallback.callUpdateViewCallback({
          displayCardForm: true,
          clearCardFormContent: true,
          registerCheckboxState: false,
          displaySubmitButton: true,
        });
      }
    } else if (response.unbindAppInstance) {
      return this.digitalTerminal.unbindAppInstance().pipe(
        tap(() => this.cardListGenerator.hideForm()),
        mapTo(response),
      );
    }

    if (response.dcfActionCode === DcfActionCode.COMPLETE) {
      this.hppUpdateViewCallback.callUpdateViewCallback({
        displayCardForm: false,
        displaySubmitButton: true,
      });
    }

    if (response.unbindAppInstance) {
      this.cardListGenerator.reset();
      return this.digitalTerminal.unbindAppInstance().pipe(
        tap(() => this.cardListGenerator.hideForm()),
        mapTo(response),
      );
    }

    return of(response);
  }

  private getDcfWindowRef(): Window {
    const formElement: HTMLFormElement = document.querySelector(
      `form#${this.initParams.formId}`,
    );
    const submitList = formElement.querySelectorAll('[type="submit"]');
    submitList.forEach(function (node, index) {
      node.setAttribute("disabled", "");
      node.classList.add("st-form__button--disabled");
    });
    const dcfFrame = document.createElement("iframe");
    dcfFrame.classList.add("st-dcf__frame");

    this.dcfContainer = document.createElement("div");
    this.dcfContainer.classList.add("st-dcf");
    this.dcfContainer.appendChild(dcfFrame);

    document.querySelector("body").appendChild(this.dcfContainer);

    return dcfFrame.contentWindow;
  }

  private disableHiddenFormFields(updateData: IUpdateView) {
    const formElement: HTMLFormElement = document.querySelector(
      `form#${this.initParams.formId}`,
    );
    const cardFieldNames: HPPFormFieldName[] = [
      HPPFormFieldName.PAN,
      HPPFormFieldName.CARD_EXPIRY_MONTH,
      HPPFormFieldName.CARD_EXPIRY_YEAR,
      HPPFormFieldName.CARD_SECURITY_CODE,
    ];

    if (!formElement) {
      return;
    }

    cardFieldNames.forEach((fieldName) => {
      const fieldElement = formElement.elements.namedItem(
        fieldName,
      ) as HTMLInputElement;
      if (updateData.displayCardForm === true) {
        fieldElement?.removeAttribute("readonly");
        fieldElement?.removeAttribute("disabled");

        if (updateData.clearCardFormContent === true) {
          fieldElement.value = "";
        }
      } else {
        fieldElement?.setAttribute("readonly", "readonly");
        fieldElement?.setAttribute("disabled", "disabled");
      }
    });

    const checkbox = formElement?.elements.namedItem(
      HPPFormFieldName.REGISTER,
    ) as HTMLInputElement;
    if (updateData.registerCheckboxState != null)
      checkbox.checked = updateData.registerCheckboxState;
  }
}
