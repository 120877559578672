import { Service } from "typedi";
import { Observable, distinctUntilChanged, map, merge, takeUntil } from "rxjs";
import { IMessageBus } from "../message-bus/IMessageBus";
import { ofType } from "../../../../shared/services/message-bus/operators/ofType";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { ConfigProvider } from "../../../../shared/services/config-provider/ConfigProvider";
import { ConfigService } from "../../../../shared/services/config-service/ConfigService";
import { BrowserLocalStorage } from "../../../../shared/services/storage/BrowserLocalStorage";
import { ITranslationChangeTracker } from "./ITranslationChangeTracker";
import { ITranslationChangeUpdater } from "./ITranslationChangeUpdater";

@Service()
export class TranslationUpdateService
  implements ITranslationChangeTracker, ITranslationChangeUpdater
{
  private latestTranslations: Record<string, string> = {};
  constructor(
    private configProvider: ConfigProvider,
    private configService: ConfigService,
    private messageBus: IMessageBus,
    private storage: BrowserLocalStorage,
  ) {
    /*When the updateRequired observable is returned, if there is no 
    updated translation, it will return the initial translation.*/
    this.latestTranslations = this.configProvider.getConfig()
      ?.translations as Record<string, string>;
  }

  updateRequired(): Observable<Record<string, string>> {
    const localChangeObservable = this.messageBus.pipe(
      ofType(PUBLIC_EVENTS.LOCALE_CHANGED),
      takeUntil(this.messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY))),
      distinctUntilChanged((prev, curr) => {
        const prevEvent = prev as { data: { locale: string } };
        const currEvent = curr as { data: { locale: string } };

        if (!prevEvent?.data || !currEvent?.data) {
          return false;
        }

        return prevEvent.data.locale === currEvent.data.locale;
      }),
    );

    const updateTranslationObservable = this.messageBus.pipe(
      ofType(PUBLIC_EVENTS.UPDATE_TRANSLATION),
      takeUntil(this.messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY))),
      distinctUntilChanged((prev, curr) => {
        const prevEvent = prev as { data: string };
        const currEvent = curr as { data: string };

        if (!prevEvent?.data || !currEvent?.data) {
          return false;
        }

        return prevEvent.data === currEvent.data;
      }),
    );

    return merge(localChangeObservable, updateTranslationObservable).pipe(
      map(() => this.latestTranslations),
    );
  }

  update(translations: Record<string, string>, replace: boolean = false): void {
    let translationConfig;

    if (replace) {
      translationConfig = translations;
    } else {
      translationConfig = this.configProvider.getConfig().translations;
      translationConfig = {
        ...translationConfig,
        ...translations,
      };
    }

    this.configService.updateProp("translations", translationConfig);
    this.latestTranslations = translationConfig as Record<string, string>;
    this.storage.setItem(
      "merchantTranslations",
      JSON.stringify(translationConfig),
    );
    this.messageBus.publish({
      type: PUBLIC_EVENTS.UPDATE_TRANSLATION,
      data: this.latestTranslations,
    });
  }
}
