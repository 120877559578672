import { Observable, Subject, catchError, from, of } from "rxjs";
import { Service } from "typedi";
import { InterFrameCommunicator } from "../../../../shared/services/message-bus/InterFrameCommunicator";
import { IMessageBusEvent } from "../../models/IMessageBusEvent";
import { IConfig } from "../../../../shared/model/config/IConfig";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { CONTROL_FRAME_IFRAME } from "../../models/constants/Selectors";
import { IControlFrameInitializationNotifier } from "./IControlFrameInitializationNotifier";
import { IControlFrameInitializationRequest } from "./IControlFrameInitializationRequest";

@Service()
export class MessageBusControlFrameInitializationManager
  implements
    IControlFrameInitializationNotifier,
    IControlFrameInitializationRequest
{
  private initNotificationStream: Subject<IConfig>;
  private eventType: string = PUBLIC_EVENTS.INIT_CONTROL_FRAME;
  constructor(private communicator: InterFrameCommunicator) {
    this.initNotificationStream = new Subject<IConfig>();
  }

  requestReceived(): Observable<IConfig> {
    this.communicator
      .whenReceive(this.eventType)
      .thenRespond((event: IMessageBusEvent<unknown>) => {
        try {
          // Parse the incoming data and push it to the initNotificationStream
          const configData: IConfig = JSON.parse(event?.data as string);

          this.initNotificationStream.next(configData);

          this.initNotificationStream.complete();
        } catch (error) {
          this.initNotificationStream.error(error); // Push the error through the initNotificationStream
        }

        // Return an observable that completes immediately, as the main logic is handled above
        return of(null);
      });

    return this.initNotificationStream.asObservable().pipe(
      catchError(() => {
        throw new Error("Failed to fetch configuration");
      }),
    );
  }

  request(config: IConfig): Observable<IConfig> {
    const queryEvent: IMessageBusEvent<string> = {
      type: this.eventType,
      data: JSON.stringify(config),
    };

    return from(this.communicator.query(queryEvent, CONTROL_FRAME_IFRAME));
  }
}
