import Joi from "joi";

export const GOOGLE_PAY_BUTTON_OPTIONS_SCHEMA: Joi.ObjectSchema =
  Joi.object().keys({
    buttonColor: Joi.string(),
    buttonLocale: Joi.string(),
    buttonRootNode: Joi.string().required(),
    buttonSizeMode: Joi.string(),
    buttonType: Joi.string(),
    buttonRadius: Joi.number().min(0).max(100),
  });
