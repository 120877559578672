import { Service } from "typedi";
import { BehaviorSubject, Subscription } from "rxjs";
import { distinctUntilChanged, first } from "rxjs/operators";
import { ICorrelatedMaskedCard } from "../digital-terminal/interfaces/ICorrelatedMaskedCard";
import { SrcNameFinder } from "../digital-terminal/SrcNameFinder";
import { DigitalTerminal } from "../digital-terminal/DigitalTerminal";
import { SrcName } from "../digital-terminal/SrcName";
import { ISrcProfileList } from "../digital-terminal/ISrc";
import { ITranslator } from "../../../application/core/shared/translator/ITranslator";
import { HPPUpdateViewCallback } from "../adapter/hpp-adapter/HPPUpdateViewCallback";
import { NewCardFieldName } from "./NewCardFieldName";
import "./CardListGenerator.scss";

const logo = require("../../../application/core/services/icon/images/click-to-pay.svg");

const panValidationStatusFailed =
  "Selected card is not currently supported for Click to Pay";

@Service()
export class CardListGenerator {
  private acceptedCards: SrcName[] = [SrcName.VISA, SrcName.MASTERCARD];
  private notYouElementId = "st-ctp-user-details__not--you";
  private panValidationStatus: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );
  private panValidationStatusSubscription: Subscription;
  private cardList: ICorrelatedMaskedCard[];
  private cardListContainer: HTMLElement;
  private selectedCard: ICorrelatedMaskedCard;

  private readonly iconMap: Map<string, string> = new Map([
    [
      "visa",
      require("../../../application/core/services/icon/images/ctp-visa.svg"),
    ],
    [
      "mastercard",
      require("../../../application/core/services/icon/images/ctp-mastercard.svg"),
    ],
  ]);
  private readonly defaultCardArt: Map<string, string> = new Map([
    [
      "visa",
      require("../../../application/core/services/icon/images/ctp-visa-fallback-card-art.svg"),
    ],
  ]);

  constructor(
    private digitalTerminal: DigitalTerminal,
    private translator: ITranslator,
    private srcNameFinder: SrcNameFinder,
    private hppUpdateViewCallback: HPPUpdateViewCallback,
  ) {}

  displayCards(
    formId: string,
    parentContainer: string,
    cardList: ICorrelatedMaskedCard[],
  ): void {
    const container: HTMLElement = document.getElementById(parentContainer);
    container.classList.add("st-cards");
    container.innerHTML = "";

    this.cardListContainer = document.createElement("div");
    this.cardListContainer.classList.add("st-cards-list");

    container.appendChild(this.cardListContainer);

    this.cardList = cardList;

    this.displayCardList(this.cardList);

    const addCardRow = document.createElement("div");
    addCardRow.classList.add("st-add-card");
    addCardRow.innerHTML = this.addCardContent();
    container.appendChild(addCardRow);

    this.addValidation();
    this.fillUpExpiryMonth();
    this.fillUpExpiryYear();
    this.addEventHandlers(formId);
  }

  private updateCardList(): ICorrelatedMaskedCard[] {
    const updatedCardList = this.cardList.filter(
      (card) => card.srcDigitalCardId !== this.selectedCard.srcDigitalCardId,
    );
    updatedCardList.unshift(this.selectedCard);

    return updatedCardList;
  }

  private displayCardList(cardList) {
    this.cardListContainer.innerHTML = "";

    if (cardList.length === 1) {
      this.displayCard(cardList[0], this.cardListContainer, true, false);
      this.selectedCard = cardList[0];
    }

    if (cardList.length > 1) {
      cardList.forEach((cardData, index) => {
        if (index === 0) {
          this.displayCard(cardData, this.cardListContainer, true, false);
          this.selectedCard = cardData;
        }

        if (index > 0) {
          this.displayCard(cardData, this.cardListContainer, false, true);
        }
      });

      const viewCardsWrapper = document.createElement("div");
      viewCardsWrapper.classList.add("st-view-all-cards");
      viewCardsWrapper.innerHTML = this.addViewCardsButtons();
      this.cardListContainer.appendChild(viewCardsWrapper);
      document.getElementById("st-view-all-card__hide-button").style.display =
        "none";
      document
        .getElementById("st-view-all-card__show-button")
        .addEventListener("click", () => this.handleViewAllCardButtonClick());
      document
        .getElementById("st-view-all-card__hide-button")
        .addEventListener("click", () => this.handleHideAllCardButtonClick());
    }
  }

  private setFallbackCartArt(
    cardArdImg: HTMLImageElement,
    card: ICorrelatedMaskedCard,
  ) {
    cardArdImg.addEventListener("error", () => {
      const defaultCardArtSrc = this.defaultCardArt.get(
        card.srcName.toLowerCase(),
      );
      if (cardArdImg.hasAttribute("data-default-img")) {
        return;
      }

      cardArdImg.src = defaultCardArtSrc;
      cardArdImg.setAttribute("data-default-img", "true");
    });
  }

  displayUserInformation(
    parentContainer: string,
    userInformation: Partial<Record<SrcName, ISrcProfileList>>,
  ): void {
    const container: HTMLElement = document.getElementById(parentContainer);
    const wrapper = document.createElement("div");

    wrapper.innerHTML = this.addUserInformationContent(
      Object.values(userInformation).filter((obj) => obj)[0].profiles[0]
        .maskedConsumer.emailAddress,
    );
    container.prepend(wrapper);
    document
      .getElementById(this.notYouElementId)
      .addEventListener("click", () =>
        this.digitalTerminal
          .unbindAppInstance()
          .subscribe(() => this.hideForm()),
      );
  }

  openNewCardForm(): void {
    this.openForm();
    this.clearSelection();
  }

  hideForm(): void {
    document.getElementById("st-ctp-cards").innerHTML = "";
    this.hppUpdateViewCallback.callUpdateViewCallback({
      displayCardForm: true,
      displaySubmitButton: true,
      displayMaskedCardNumber: null,
      displayCardType: null,
    });
  }

  reset() {
    this.clearForm();
    this.clearSelection();
  }

  private addCardContent(): string {
    return `
      <div class='st-add-card__label'>
        <span class='st-add-card__label st-add-card__button' id='st-add-card__button'>
          +&emsp;${this.translator.translate("Add a card")}
        </span>
        <span class='st-add-card__label st-add-card__title' id='st-add-card__title'>
          ${this.translator.translate("Add new card")}
        </span>
      </div>
      <div class='st-add-card__details'>
        ${this.translator.translate(
          "Card number",
        )} <span class='st-add-card__details-asterix'></span>
        <input id='vctp-pan' type='text' autocomplete='off' name='${
          NewCardFieldName.PAN
        }'>
        <div id='vctp-pan-validation-status' class='st-add-card__pan-validation'></div>
      </div>
      <div class='st-add-card__details'>
        <span class='st-add-card__details-element'>
          ${this.translator.translate(
            "Expiry date",
          )} <span class='st-add-card__details-asterix'></span>
          <select id='vctp-expiryDateMonthId' autocomplete='off' name='${
            NewCardFieldName.EXPIRY_MONTH
          }'></select>
        </span>
        <span class='st-add-card__details-element'>
          <select id='vctp-expiryDateYearId' autocomplete='off' name='${
            NewCardFieldName.EXPIRY_YEAR
          }'></select>
        </span>
      </div>
      <div class='st-add-card__details'>
        ${this.translator.translate(
          "Security code",
        )} <span class='st-add-card__details-asterix'></span><br>
        <input id='vctp-cvv' maxlength='3' autocomplete='off' name='${
          NewCardFieldName.SECURITY_CODE
        }' type='text'>
      </div>
    `;
  }

  private addViewCardsButtons(): string {
    return `
      <div class='st-add-card__label st-view-all-card__button' id='st-view-all-card__show-button'>
        <span class='st-add-card__label'>
          ${this.translator.translate("View all cards")}
        </span>
      </div>
      <div class='st-add-card__label st-view-all-card__button' id='st-view-all-card__hide-button'>
        <span class='st-add-card__label' >
          ${this.translator.translate("Hide cards")}
        </span>
      </div>
    `;
  }

  private addEventHandlers(formId: string): void {
    document
      .getElementById(formId)
      .querySelector("input[name=" + NewCardFieldName.PAN + "]")
      .addEventListener("change", (event) => this.handleChangedPan(event));
    document
      .getElementById("st-add-card__button")
      .addEventListener("click", () => this.handleAddCardButtonClick());
  }

  private addUserInformationContent(emailAddress: string): string {
    return `
      <div class='st-ctp-prompt__logo'>
        <img src='${logo}' class='st-ctp-prompt__logo-img' alt=''>
        <span>Click to Pay</span>
      </div>
      <div id='st-ctp-user-details__wrapper' class='st-ctp-user-details__wrapper'>
        ${emailAddress} <span id='st-ctp-user-details__not--you' class='st-ctp-user-details-not-you'>${this.translator.translate(
          "Not you?",
        )}</span>
      </div>
    `;
  }

  private addValidation(): void {
    if (this.panValidationStatusSubscription) {
      this.panValidationStatusSubscription.unsubscribe();
    }
    this.panValidationStatusSubscription = this.panValidationStatus
      .pipe(distinctUntilChanged())
      .subscribe((result) =>
        result
          ? this.hideValidationStatus("vctp-pan-validation-status")
          : this.showValidationStatus(
              "vctp-pan-validation-status",
              panValidationStatusFailed,
            ),
      );
  }

  private getMessage(status): string {
    let message: string;
    switch (status) {
      case "EXPIRED":
        message = this.translator.translate("This card is expired");
        break;
      case "SUSPENDED":
        message = this.translator.translate("This card is suspended");
        break;
      case "PENDING":
        message = this.translator.translate("This card is not active");
        break;
      default:
        message = "";
    }
    return message;
  }

  private displayCard(
    cardData: ICorrelatedMaskedCard,
    container: HTMLElement,
    checked: boolean,
    hidden: boolean,
  ): void {
    const newCard = document.createElement("div");
    newCard.classList.add("st-card");

    const check = checked && " checked";
    let message = "";

    if (!cardData.isActive) {
      message = this.getMessage(cardData.digitalCardData.status);
      newCard.classList.add("st-card--inactive");
    } else {
      newCard.addEventListener("click", () =>
        this.selectCard(cardData.srcDigitalCardId),
      );
    }

    if (hidden) {
      newCard.classList.add("st-card--hidden");
    }

    if (checked) {
      this.hppUpdateViewCallback.callUpdateViewCallback({
        displayMaskedCardNumber: cardData.panLastFour.toString(),
        displayCardType: cardData.srcName.toLowerCase(),
        displayCardForm: false,
        displaySubmitButton: true,
      });
    }

    newCard.innerHTML = `
        <div class="st-card__checkbox">
        ${
          cardData.isActive
            ? '<label><input id="radio' +
              cardData.srcDigitalCardId +
              '" name="srcDigitalCardId" class="st-card__checkbox-input" type="radio" value="' +
              cardData.srcDigitalCardId +
              '"' +
              check +
              '><span class="st-card__checkbox-radio"></span></label>'
            : ""
        }
      </div>
      <div class='st-card__details'>
        <div class='st-card__image'>
            <img src='${
              cardData.digitalCardData.artUri
            }' alt='' style='width: 60px; height: 40px' data-card-src='${
              cardData.srcName
            }'>
        </div>
        <div class='st-card__description'>
            ${cardData.srcName}<br>
            ..${cardData.panLastFour}
        </div>
        <div class='st-card__logo'>
            <img src='${this.iconMap.get(
              cardData.srcName.toLowerCase(),
            )}' alt='${cardData.srcName.toLowerCase()} logo' data-src-name='${cardData.srcName.toLowerCase()}'>
        </div>
      </div>
      ${message && '<div class="st-card__status">' + message + "</div>"}
    `;

    this.setFallbackCartArt(
      newCard.querySelector("img[data-card-src]"),
      cardData,
    );

    container.appendChild(newCard);
  }

  private clearForm(): void {
    const newCardFormFieldNames = [
      NewCardFieldName.PAN,
      NewCardFieldName.SECURITY_CODE,
      NewCardFieldName.EXPIRY_YEAR,
      NewCardFieldName.EXPIRY_MONTH,
    ];

    newCardFormFieldNames
      .map((name) => document.querySelector(`[name="${name}"]`))
      .filter(Boolean)
      .forEach((inputElement: unknown) => {
        (inputElement as HTMLInputElement | HTMLSelectElement).value = "";
      });
    this.panValidationStatus.next(true);
  }

  private clearSelection(): void {
    document
      .getElementsByName("srcDigitalCardId")
      .forEach((element: unknown) => {
        (element as HTMLInputElement).checked = false;
      });

    if (document.getElementsByName("srcDigitalCardId")?.length) {
      this.hppUpdateViewCallback.callUpdateViewCallback({
        displayMaskedCardNumber: null,
        displayCardType: null,
        displayCardForm: false,
        displaySubmitButton: true,
      });
    }
  }

  private fillUpExpiryMonth(): void {
    const select = document.getElementById("vctp-expiryDateMonthId");
    const option = document.createElement("option") as HTMLOptionElement;
    option.value = option.innerHTML = "";
    select.appendChild(option);
    for (let i = 0; i < 12; i++) {
      const option = document.createElement("option") as HTMLOptionElement;
      option.value = (i + 1).toString().padStart(2, "0");
      option.innerHTML = option.value;
      select.appendChild(option);
    }
  }

  private fillUpExpiryYear(): void {
    const select = document.getElementById("vctp-expiryDateYearId");
    const currentYear = new Date().getFullYear();
    const option = document.createElement("option") as HTMLOptionElement;
    option.value = option.innerHTML = "";
    select.appendChild(option);
    for (let i = currentYear; i < currentYear + 21; i++) {
      const option = document.createElement("option") as HTMLOptionElement;
      option.value = option.innerHTML = i.toString();
      select.appendChild(option);
    }
  }

  private handleAddCardButtonClick(): void {
    this.hppUpdateViewCallback.callUpdateViewCallback({
      displayMaskedCardNumber: null,
      displayCardType: null,
      displayCardForm: false,
      displaySubmitButton: true,
    });
    this.openForm();
    this.clearSelection();
  }

  private handleViewAllCardButtonClick(): void {
    document.getElementById("st-view-all-card__show-button").style.display =
      "none";
    document.getElementById("st-view-all-card__hide-button").style.display = "";
    document.querySelectorAll(".st-card.st-card--hidden").forEach((e) => {
      (e as HTMLDivElement).classList.remove("st-card--hidden");
    });
  }

  private handleHideAllCardButtonClick(): void {
    document.getElementById("st-view-all-card__show-button").style.display = "";
    document.getElementById("st-view-all-card__hide-button").style.display =
      "none";

    const updatedCardList = this.updateCardList();
    this.displayCardList(updatedCardList);
  }

  private handleChangedPan(event: Event): void {
    if ((event.target as HTMLInputElement).value) {
      this.srcNameFinder
        .findSrcNameByPan((event.target as HTMLInputElement).value)
        .pipe(first())
        .subscribe((result: SrcName | null) => {
          this.panValidationStatus.next(
            this.acceptedCards.indexOf(result) !== -1,
          );
        });
    } else {
      this.panValidationStatus.next(true);
    }
  }

  private selectCard(id: string): void {
    this.closeForm();
    this.clearForm();
    this.clearSelection();
    const checkboxElement = document.getElementById(
      "radio" + id,
    ) as HTMLInputElement;
    checkboxElement.checked = true;
    this.selectedCard = this.cardList.filter(
      (card) => card.srcDigitalCardId === id,
    )[0];

    this.hppUpdateViewCallback.callUpdateViewCallback({
      displayMaskedCardNumber: this.selectedCard.panLastFour.toString(),
      displayCardType: this.selectedCard.srcName.toLowerCase(),
      displayCardForm: false,
      displaySubmitButton: true,
    });
  }

  private hideValidationStatus(id: string) {
    document.getElementById(id).style.display = "none";
    document.getElementById(id).innerHTML = "";
  }

  private openForm(): void {
    if (!document.getElementById("st-add-card__button")) {
      return;
    }

    document.querySelector(".st-add-card").classList.add("st-add-card--open");
    document.getElementById("st-add-card__button").style.display = "none";
    document.getElementById("st-add-card__title").style.display = "block";
    document.querySelectorAll("div.st-add-card__details").forEach((div) => {
      (div as HTMLDivElement).style.display = "block";
    });
    this.toggleNewCardFormValidation(true);
  }

  private closeForm(): void {
    document
      .querySelector(".st-add-card")
      .classList.remove("st-add-card--open");
    document.getElementById("st-add-card__button").style.display = "block";
    document.getElementById("st-add-card__title").style.display = "none";
    document.querySelectorAll("div.st-add-card__details").forEach((div) => {
      (div as HTMLDivElement).style.display = "none";
    });
    this.toggleNewCardFormValidation(false);
  }

  private showValidationStatus(id: string, message: string) {
    document.getElementById(id).style.display = "block";
    document.getElementById(id).innerHTML = message;
  }

  private toggleNewCardFormValidation(enabled: boolean) {
    const cardFieldNames: NewCardFieldName[] = [
      NewCardFieldName.PAN,
      NewCardFieldName.EXPIRY_YEAR,
      NewCardFieldName.EXPIRY_MONTH,
      NewCardFieldName.SECURITY_CODE,
    ];

    cardFieldNames.forEach((fieldName) => {
      const fieldElement = document.querySelector(
        `[name="${fieldName}"]`,
      ) as Element;
      if (enabled) {
        fieldElement?.setAttribute("required", "required");
      } else {
        fieldElement?.removeAttribute("required");
      }
    });
  }
}
