/* istanbul ignore file */
import { IEnhancedApplePayPaymentRequest } from "../models/IEnhancedApplePayPaymentRequest";
import { IApplePaySession } from "../models/IApplePaySession";
import { IJwtEnhancedApplePayShippingContactUpdate } from "../models/IJwtEnhancedApplePayShippingContactUpdate";
import { IJwtEnhancedApplePayShippingMethodUpdate } from "../models/IJwtEnhancedApplePayShippingMethodUpdate";
import { ISelectableApplePayShippingMethod } from "../models/ISelectableApplePayShippingMethod";
import { ApplePayMockUiController } from "./ApplePayMockUiController";
import { IEnhancedApplePayShippingMethodChangedEvent } from "../models/IEnhancedApplePayShippingMethodChangedEvent";

export class MockedApplePaySession implements IApplePaySession {
  private mockUserInterface: ApplePayMockUiController;
  private paymentRequest: IEnhancedApplePayPaymentRequest;

  oncancel: (event: ApplePayJS.Event) => void;
  onpaymentauthorized: (
    event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
  ) => void;
  onvalidatemerchant: (event: ApplePayJS.ApplePayValidateMerchantEvent) => void;
  onshippingmethodselected: (
    event: IEnhancedApplePayShippingMethodChangedEvent,
  ) => void;

  constructor(paymentRequest: IEnhancedApplePayPaymentRequest) {
    this.paymentRequest = paymentRequest;
    this.mockUserInterface = new ApplePayMockUiController(this.paymentRequest);
    this.proceedPayment = this.proceedPayment.bind(this);
  }

  completeShippingContactSelection(
    update: IJwtEnhancedApplePayShippingContactUpdate,
  );
  completeShippingContactSelection(
    status: number,
    newShippingMethods: ISelectableApplePayShippingMethod[],
    newTotal: object,
    newLineItems: object[],
  ): void;
  completeShippingContactSelection(
    status: unknown,
    newShippingMethods?: unknown,
    newTotal?: unknown,
    newLineItems?: unknown,
  ): void {
    if (typeof status === "number") {
      // Handle Apple Pay versions 1 and 2
      this.mockUserInterface.updateShippingAmountChanges(
        status,
        newShippingMethods as ISelectableApplePayShippingMethod[],
        newTotal as ApplePayJS.ApplePayLineItem,
        newLineItems as ApplePayJS.ApplePayLineItem[],
      );
    } else if (status) {
      // Handle Apple Pay version 3+
      const data = status as IJwtEnhancedApplePayShippingContactUpdate;
      this.mockUserInterface.updateShippingAmountChanges(
        data.errors,
        data.newShippingMethods,
        data.newTotal,
        data.newLineItems,
      );
    }
  }

  onshippingcontactselected: (
    event: ApplePayJS.ApplePayShippingContactSelectedEvent,
  ) => void;

  completeShippingMethodSelection(
    update: IJwtEnhancedApplePayShippingMethodUpdate,
  ): void;
  completeShippingMethodSelection(
    status: number,
    newTotal: object,
    newLineItems: object[],
  ): void;
  completeShippingMethodSelection(
    status: unknown,
    newTotal?: unknown,
    newLineItems?: unknown,
  ): void {
    this.mockUserInterface.updateShippingMethodAmountChanges(
      status,
      newTotal as ApplePayJS.ApplePayLineItem,
      newLineItems as ApplePayJS.ApplePayLineItem[],
    );
  }

  public begin(): void {
    this.mockUserInterface.open(this);
    this.onvalidatemerchant({
      validationURL: "https://webservices-mock.securetrading.net:6443/jwt/",
      bubbles: false,
      cancelBubble: false,
      cancelable: false,
      composed: false,
      currentTarget: undefined,
      defaultPrevented: false,
      eventPhase: 0,
      isTrusted: false,
      returnValue: false,
      srcElement: undefined,
      target: undefined,
      timeStamp: "",
      type: "",
      composedPath: function (): Node[] {
        throw new Error("Function not implemented.");
      },
      initEvent: function (
        type?: string,
        bubbles?: boolean,
        cancelable?: boolean,
      ): void {
        throw new Error("Function not implemented.");
      },
      preventDefault: function (): void {
        throw new Error("Function not implemented.");
      },
      stopImmediatePropagation: function (): void {
        throw new Error("Function not implemented.");
      },
      stopPropagation: function (): void {
        throw new Error("Function not implemented.");
      },
    });
  }

  public abort(): void {
    this.mockUserInterface.close(this);
  }

  public proceedPayment(contact?: ApplePayJS.ApplePayPayment) {
    const applePayPaymentAuthorizedEvent = {
      payment: {
        token: {
          paymentData: "mockPaymentData",
          transactionIdentifier: "mockTransactionIdentifier",
          paymentMethod: {
            displayName: "Visa",
            network: "VISA",
            type: "credit",
            paymentPass: {
              primaryAccountIdentifier: "mockPrimaryAccountIdentifier",
              primaryAccountNumberSuffix: "1234",
              deviceAccountIdentifier: "mockDeviceAccountIdentifier",
              deviceAccountNumberSuffix: "5678",
              activationState: "activated",
            },
          },
        },
        shippingContact: contact.shippingContact,
        billingContact: contact.billingContact,
      },
    };

    this.onpaymentauthorized(
      applePayPaymentAuthorizedEvent as ApplePayJS.ApplePayPaymentAuthorizedEvent,
    );
  }

  public completeMerchantValidation(merchantSession: unknown): void {
    this.mockUserInterface.unlockPaymentButton();
  }

  public completePayment(
    status: ApplePayJS.ApplePayPaymentAuthorizationResult,
  ): void {
    this.mockUserInterface.close(this);
  }
}
