import { EMPTY, firstValueFrom, Observable, Subject, Subscription } from "rxjs";
import {
  delay,
  map,
  mapTo,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { Container, Inject, Service } from "typedi";
import { IErrorEvent } from "../../application/core/models/IErrorEvent";
import { IStJwtPayload } from "../../application/core/models/IStJwtPayload";
import { ISubmitEvent } from "../../application/core/models/ISubmitEvent";
import { ISuccessEvent } from "../../application/core/models/ISuccessEvent";
import { EventScope } from "../../application/core/models/constants/EventScope";
import { PUBLIC_EVENTS } from "../../application/core/models/constants/EventTypes";
import {
  EXPOSED_EVENTS,
  ExposedEventsName,
} from "../../application/core/models/constants/ExposedEvents";
import { CONTROL_FRAME_IFRAME } from "../../application/core/models/constants/Selectors";
import { DEFAULT_CONFIG } from "../../application/core/models/constants/config-resolver/DefaultConfig";
import { type IInternalsMonitor } from "../../application/core/services/monitoring/IInternalsMonitor";
import { InternalsMonitor } from "../../application/core/services/monitoring/InternalsMonitor";
import { InternalsMonitorActivityCategories } from "../../application/core/services/monitoring/InternalsMonitorActivityCategories";
import { IInitPaymentMethod } from "../../application/core/services/payments/events/IInitPaymentMethod";
import { IScriptOriginPublisher } from "../../application/core/services/st-codec/IScriptOriginPublisher";
import { IScriptOriginStream } from "../../application/core/services/st-codec/IScriptOriginStream";
import { OriginTag } from "../../application/core/services/st-codec/ScriptOriginTagger";
import { ISetPartialConfig } from "../../application/core/services/store-config-provider/events/ISetPartialConfig";
import { Frame } from "../../application/core/shared/frame/Frame";
import { IControlFrameInitializationRequest } from "../../application/core/shared/message-bus/IControlFrameInitializationRequest";
import { IMessageBus } from "../../application/core/shared/message-bus/IMessageBus";
import { MESSAGE_BUS } from "../../application/core/shared/message-bus/MessageBus";
import { Notification } from "../../application/core/shared/notification/Notification";
import "../../application/core/shared/override-domain/OverrideDomain";
import { type ITranslationChangeUpdater } from "../../application/core/shared/translator/ITranslationChangeUpdater";
import { ITranslator } from "../../application/core/shared/translator/ITranslator";
import { IStore } from "../../application/core/store/IStore";
import { IParentFrameState } from "../../application/core/store/state/IParentFrameState";
import { ENVIRONMENT } from "../../environments/environment";
import { IAPMConfig } from "../../integrations/apm/models/IAPMConfig";
import { APM_PAYMENT_METHOD_NAME } from "../../integrations/apm/models/IAPMPaymentMethod";
import { IApplePayConfig } from "../../integrations/apple-pay/client/models/IApplePayConfig";
import { APPLE_PAY_CONFIG_NAME } from "../../integrations/apple-pay/models/IApplePayConfig";
import { APPLE_PAY_PAYMENT_METHOD_NAME } from "../../integrations/apple-pay/models/IApplePayPaymentMethod";
import { ClickToPayAdapterFactory } from "../../integrations/click-to-pay/adapter/ClickToPayAdapterFactory";
import { HPPClickToPayAdapter } from "../../integrations/click-to-pay/adapter/hpp-adapter/HPPClickToPayAdapter";
import { IClickToPayAdapterInitParams } from "../../integrations/click-to-pay/adapter/interfaces/IClickToPayAdapterInitParams";
import { IClickToPayAdapter } from "../../integrations/click-to-pay/adapter/interfaces/IClickToPayClientAdapter";
import { IClickToPayConfig } from "../../integrations/click-to-pay/models/IClickToPayConfig";
import {
  GooglePayConfigName,
  IGooglePayConfig,
} from "../../integrations/google-pay/models/IGooglePayConfig";
import { GOOGLE_PAYMENT_METHOD_NAME } from "../../integrations/google-pay/models/IGooglePaymentMethod";
import {
  PayPalConfigName,
  PayPalPaymentMethodName,
} from "../../integrations/paypal/constants/PayPalConstants";
import { IPayPalConfig } from "../../integrations/paypal/models/IPayPalConfig";
import { TokenizedCardPaymentAdapter } from "../../integrations/tokenized-card/application/TokenizedCardPaymentAdapter";
import {
  ITokenizedCardPaymentConfig,
  ITokenizedCardPaymentConfigDeprecated,
} from "../../integrations/tokenized-card/models/ITokenizedCardPayment";
import {
  TokenizedCardPaymentConfigName,
  TokenizedCardPaymentMethodName,
} from "../../integrations/tokenized-card/models/ITokenizedCardPaymentMethod";
import { TRANSLATION_CHANGE_TRACKER_TOKEN } from "../../shared/dependency-injection/InjectionTokens";
import { AppVersion } from "../../shared/model/AppVersion";
import { IComponentsConfig } from "../../shared/model/config/IComponentsConfig";
import { IConfig } from "../../shared/model/config/IConfig";
import { BrowserDetector } from "../../shared/services/browser-detector/BrowserDetector";
import { IBrowserInfo } from "../../shared/services/browser-detector/IBrowserInfo";
import { ConfigProvider } from "../../shared/services/config-provider/ConfigProvider";
import { ConfigService } from "../../shared/services/config-service/ConfigService";
import { JwtDecoder } from "../../shared/services/jwt-decoder/JwtDecoder";
import { FramesHub } from "../../shared/services/message-bus/FramesHub";
import { InterFrameCommunicator } from "../../shared/services/message-bus/InterFrameCommunicator";
import { ofType } from "../../shared/services/message-bus/operators/ofType";
import { BrowserLocalStorage } from "../../shared/services/storage/BrowserLocalStorage";
import { CardFrames } from "../card-frames/CardFrames";
import { ClientBootstrap } from "../client-bootstrap/ClientBootstrap";
import { CommonFrames } from "../common-frames/CommonFrames";
import { IframeFactory } from "../iframe-factory/IframeFactory";
import { CardinalClient } from "../integrations/cardinal-commerce/CardinalClient";
import { ThreeDSecureClient } from "../integrations/three-d-secure/ThreeDSecureClient";
import { MerchantFields } from "../merchant-fields/MerchantFields";
import { NotificationService } from "../notification/NotificationService";
import "./st.css";
import { RequestJwtUpdateHandler } from "../../application/core/services/st-codec/RequestJwtUpdateHandler";

@Service()
export class ST {
  private controlFrameLoader$: Observable<IConfig>;
  private destroy$: Subject<void> = new Subject();
  private registeredCallbacks: Map<keyof typeof EXPOSED_EVENTS, Subscription> =
    new Map();

  set submitCallback(callback: (event: ISubmitEvent) => void) {
    if (callback) {
      this.on(ExposedEventsName.SUBMIT, callback as (event: unknown) => void);
    } else {
      this.off(ExposedEventsName.SUBMIT);
    }
  }

  set successCallback(callback: (event: ISuccessEvent) => void) {
    if (callback) {
      this.on(ExposedEventsName.SUCCESS, callback as (event: unknown) => void);
    } else {
      this.off(ExposedEventsName.SUCCESS);
    }
  }

  set errorCallback(callback: (event: IErrorEvent) => void) {
    if (callback) {
      this.on(ExposedEventsName.ERROR, callback as (event: unknown) => void);
    } else {
      this.off(ExposedEventsName.ERROR);
    }
  }

  set cancelCallback(callback: (event: IErrorEvent) => void) {
    if (callback) {
      this.on(ExposedEventsName.CANCEL, callback as (event: unknown) => void);
    } else {
      this.off(ExposedEventsName.CANCEL);
    }
  }

  constructor(
    private browserDetector: BrowserDetector,
    private cardinalClient: CardinalClient,
    private threeDSecureClient: ThreeDSecureClient,
    private communicator: InterFrameCommunicator,
    private configProvider: ConfigProvider,
    private configService: ConfigService,
    private frameService: Frame,
    private framesHub: FramesHub,
    private iframeFactory: IframeFactory,
    private jwtDecoder: JwtDecoder,
    private messageBus: IMessageBus,
    private notification: Notification,
    private notificationService: NotificationService,
    private storage: BrowserLocalStorage,
    private store: IStore<IParentFrameState>,
    private commonFrames: CommonFrames,
    private translation: ITranslator,
    private merchantFields: MerchantFields,
    private cardFrames: CardFrames,
    private tokenizedCardPaymentAdapter: TokenizedCardPaymentAdapter,
    private clickToPayAdapterFactory: ClickToPayAdapterFactory,
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
    private appVersion: AppVersion,
    private frameInitializer: IControlFrameInitializationRequest,
    private scriptOriginStream: IScriptOriginStream,
    private scriptOriginPublisher: IScriptOriginPublisher,
    @Inject(TRANSLATION_CHANGE_TRACKER_TOKEN)
    private translationUpdateService: ITranslationChangeUpdater,
    private jwtRequestHandler: RequestJwtUpdateHandler,
  ) {}

  on(
    eventName: keyof typeof EXPOSED_EVENTS,
    callback: (event: unknown) => void,
  ): void {
    this.off(eventName);
    this.registeredCallbacks[eventName] = this.messageBus
      .pipe(
        ofType(EXPOSED_EVENTS[eventName]),
        map((event) => event.data),
        delay(0),
        takeUntil(this.destroy$),
      )
      .subscribe(callback);
  }

  off(eventName: keyof typeof EXPOSED_EVENTS): void {
    if (this.registeredCallbacks[eventName]) {
      this.registeredCallbacks[eventName].unsubscribe();
      this.registeredCallbacks[eventName] = undefined;
    }
  }

  Components(config: IComponentsConfig | undefined): void {
    this.internalMonitor.recordActivity("User called st.Components", {
      config,
    });
    this.messageBus.publish<ISetPartialConfig<IComponentsConfig>>(
      {
        type: PUBLIC_EVENTS.PARTIAL_CONFIG_SET,
        data: {
          name: "components",
          config,
        },
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    if (config) {
      this.configService.updateFragment("components", config);
    }

    this.initControlFrame$().subscribe(() => {
      this.cardFrames.init();
      this.messageBus.publish<string>(
        {
          type: PUBLIC_EVENTS.CARD_PAYMENTS_INIT,
          data: JSON.stringify(this.configProvider.getConfig()),
        },
        EventScope.THIS_FRAME,
      );
    });
  }

  getVersion(): string {
    this.internalMonitor.recordActivity("User called st.getVersion");
    return this.appVersion.getVersion();
  }

  APM(config: IAPMConfig): void {
    this.internalMonitor.recordActivity("User called st.APM", { config });
    this.messageBus.publish<ISetPartialConfig<IAPMConfig>>(
      {
        type: PUBLIC_EVENTS.PARTIAL_CONFIG_SET,
        data: {
          name: APM_PAYMENT_METHOD_NAME,
          config,
        },
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    this.initControlFrame$().subscribe(() => {
      this.messageBus.publish<IInitPaymentMethod<IAPMConfig>>(
        {
          type: PUBLIC_EVENTS.INIT_PAYMENT_METHOD,
          data: {
            name: APM_PAYMENT_METHOD_NAME,
            config,
          },
        },
        EventScope.THIS_FRAME,
      );
    });
  }

  ApplePay(config: IApplePayConfig): void {
    this.internalMonitor.recordActivity("User called st.ApplePay", { config });
    this.messageBus.publish<ISetPartialConfig<IApplePayConfig>>(
      {
        type: PUBLIC_EVENTS.PARTIAL_CONFIG_SET,
        data: {
          name: APPLE_PAY_PAYMENT_METHOD_NAME,
          config,
        },
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    if (config) {
      this.configService.updateFragment(APPLE_PAY_CONFIG_NAME, config);
    }

    this.initControlFrame$().subscribe(() => {
      this.messageBus.publish<IInitPaymentMethod<IConfig>>(
        {
          type: PUBLIC_EVENTS.INIT_PAYMENT_METHOD,
          data: {
            name: APPLE_PAY_PAYMENT_METHOD_NAME,
            config: this.configService.getConfig(),
          },
        },
        EventScope.THIS_FRAME,
      );
    });
  }

  GooglePay(config: IGooglePayConfig): void {
    this.internalMonitor.recordActivity("User called st.GooglePay", { config });
    this.messageBus.publish<ISetPartialConfig<IGooglePayConfig>>(
      {
        type: PUBLIC_EVENTS.PARTIAL_CONFIG_SET,
        data: {
          name: GOOGLE_PAYMENT_METHOD_NAME,
          config,
        },
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    if (config) {
      this.configService.updateFragment(GooglePayConfigName, config);
    }

    this.initControlFrame$().subscribe(() => {
      this.messageBus.publish<IInitPaymentMethod<IConfig>>(
        {
          type: PUBLIC_EVENTS.INIT_PAYMENT_METHOD,
          data: {
            name: GOOGLE_PAYMENT_METHOD_NAME,
            config: this.configService.getConfig(),
          },
        },
        EventScope.THIS_FRAME,
      );
    });
  }

  PayPal(config: IPayPalConfig): void {
    this.internalMonitor.recordActivity("User called st.PayPal", {
      config,
    });
    this.messageBus.publish<ISetPartialConfig<IPayPalConfig>>(
      {
        type: PUBLIC_EVENTS.PARTIAL_CONFIG_SET,
        data: {
          name: PayPalPaymentMethodName,
          config,
        },
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    if (config) {
      this.configService.updateFragment(PayPalConfigName, config);
    }
    this.initControlFrame$().subscribe(() => {
      this.messageBus.publish<IInitPaymentMethod<IConfig>>(
        {
          type: PUBLIC_EVENTS.INIT_PAYMENT_METHOD,
          data: {
            name: PayPalPaymentMethodName,
            config: this.configService.getConfig(),
          },
        },
        EventScope.THIS_FRAME,
      );
    });
  }

  TokenizedCardPayment(
    jwtCard: string,
    tokenizedCardPaymentConfig?:
      | ITokenizedCardPaymentConfigDeprecated
      | ITokenizedCardPaymentConfig,
  ): Promise<TokenizedCardPaymentAdapter> | undefined {
    this.internalMonitor.recordActivity("User called st.TokenizedCardPayment", {
      config: tokenizedCardPaymentConfig,
    });
    if (!jwtCard) {
      return undefined;
    }

    tokenizedCardPaymentConfig = {
      ...DEFAULT_CONFIG[TokenizedCardPaymentConfigName],
      ...this.configService.getConfig()[TokenizedCardPaymentConfigName],
      ...tokenizedCardPaymentConfig,
    };

    if (
      (tokenizedCardPaymentConfig as ITokenizedCardPaymentConfigDeprecated)
        ?.style
    ) {
      (tokenizedCardPaymentConfig as ITokenizedCardPaymentConfig).styles = {
        ...(tokenizedCardPaymentConfig as ITokenizedCardPaymentConfig).styles,
        ...(tokenizedCardPaymentConfig as ITokenizedCardPaymentConfigDeprecated)
          .style,
      };

      delete (
        tokenizedCardPaymentConfig as ITokenizedCardPaymentConfigDeprecated
      ).style;
      console.warn("tokenizedCardPaymentConfig.style is deprecated");
    }

    this.configService.updateProp(
      TokenizedCardPaymentConfigName,
      tokenizedCardPaymentConfig,
    );

    this.tokenizedCardPaymentAdapter.updateTokenizedJWT(jwtCard);
    this.configService.updateFragment(
      TokenizedCardPaymentConfigName,
      tokenizedCardPaymentConfig,
    );

    this.messageBus.publish<ISetPartialConfig<ITokenizedCardPaymentConfig>>(
      {
        type: PUBLIC_EVENTS.PARTIAL_CONFIG_SET,
        data: {
          name: TokenizedCardPaymentMethodName,
          config: tokenizedCardPaymentConfig,
        },
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    this.initControlFrame$().subscribe(() => {
      this.messageBus.publish<IInitPaymentMethod<ITokenizedCardPaymentConfig>>(
        {
          type: PUBLIC_EVENTS.INIT_PAYMENT_METHOD,
          data: {
            name: TokenizedCardPaymentMethodName,
            config: tokenizedCardPaymentConfig,
          },
        },
        EventScope.THIS_FRAME,
      );
    });

    return new Promise((resolve) => {
      resolve(this.tokenizedCardPaymentAdapter);
    });
  }

  ClickToPay(
    clickToPayConfig: IClickToPayConfig,
  ): Promise<
    IClickToPayAdapter<IClickToPayAdapterInitParams, any> | HPPClickToPayAdapter
  > {
    this.internalMonitor.recordActivity("User called st.ClickToPay", {
      config: clickToPayConfig,
      windowNavigator: window.navigator,
    });
    if (
      window.navigator.userAgent.indexOf("MSIE") > -1 ||
      window.navigator.userAgent.indexOf("Trident/") > -1
    ) {
      const message = "ClickToPay is not available on Internet Explorer";
      console.warn(message);
      return Promise.reject(message);
    }

    return firstValueFrom(
      this.initControlFrame$().pipe(
        mapTo(this.clickToPayAdapterFactory.create(clickToPayConfig.adapter)),
      ),
    );
  }

  updateJWT(jwt: string): void {
    this.internalMonitor.recordActivity("User called st.updateJWT");
    this.jwtRequestHandler.request(jwt);
  }

  updateTranslations(
    translations: Record<string, string>,
    replace: boolean = false,
  ): void {
    this.internalMonitor.recordActivity("User called st.updateTranslations", {
      translations,
      replace,
    });
    if (
      translations === null ||
      typeof translations !== "object" ||
      Array.isArray(translations)
    ) {
      const errorMessage =
        "The translation object provided to updateTranslations must be a non-null object";
      console.error(errorMessage);
      return;
    }

    if (typeof replace !== "boolean") {
      const errorMessage =
        "The replace parameter for updateTranslations must be a boolean value";
      console.error(errorMessage);
      return;
    }
    this.internalMonitor.recordActivity(
      InternalsMonitorActivityCategories.TRANSLATIONS_UPDATED,
      translations,
    );
    this.translationUpdateService.update(translations, replace);
  }

  destroy(): void {
    this.internalMonitor.recordActivity("User called st.destroy");
    this.framesHub.reset();
    this.messageBus.publish(
      {
        type: MESSAGE_BUS.EVENTS_PUBLIC.DESTROY,
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    this.destroy$.next();
    this.destroy$.complete();
    this.communicator.close();
    this.controlFrameLoader$ = undefined;
  }

  init(config: IConfig): void {
    /**
     * Here is where we start the internals Monitor.
     * Currently the InternalsMonitor entry point.
     *
     * TODO:
     * Review if there's any consideration for defered init.
     */

    this.internalMonitor.recordActivity("The library was initialised", config);

    const scriptTags = document.getElementsByTagName("script");

    // Ensure we subscribe to the event first
    this.scriptOriginStream.getLibraryOrigin$().subscribe((tag) => {
      if (
        tag &&
        tag.tag == OriginTag.CUSTOM &&
        ENVIRONMENT.production &&
        !tag.url.includes("localhost")
      ) {
        const errorMessage =
          "ST.js is being self-hosted. This is not a supported use-case of the library due to incompatibility with other hosted services that this library connects to. Please update your code to download the script from https://cdn.eu.trustpayments.com/js/latest/st.js";
        console.error(errorMessage);
        if (config.livestatus == 1) {
          InternalsMonitor.getInstance().recordIssue(new Error(errorMessage));
        }
      }
    });

    // Publish the event
    this.scriptOriginPublisher.findThenPublish(scriptTags, "st.js");

    this.messageBus.publish<ISetPartialConfig<IConfig>>(
      {
        type: PUBLIC_EVENTS.PARTIAL_CONFIG_SET,
        data: {
          name: "config",
          config,
        },
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    this.framesHub.reset();
    this.storage.init();
    const finalConfig = this.configService.setup(config);

    if (finalConfig.jwt) {
      this.initCallbacks(config);
      this.manageStorage();
      this.commonFrames.init();
      this.displayLiveStatus(Boolean(finalConfig.livestatus));
      this.watchForFrameUnload();
      this.cardinalClient.init();
      this.threeDSecureClient.init();

      if (finalConfig.stopSubmitFormOnEnter) {
        this.stopSubmitFormOnEnter();
      }
    } else {
      this.internalMonitor.recordIssue(
        new Error("Invalid, undefined or missing 'JWT'"),
        this.configProvider.getConfig(),
      );
    }
    return;
  }

  getBrowserInfo(): IBrowserInfo {
    this.internalMonitor.recordActivity("User called st.getBrowserInfo");
    return this.browserDetector.getBrowserInfo();
  }

  cancelThreeDProcess(): void {
    this.internalMonitor.recordActivity("User called st.cancelThreeDProcess");
    this.messageBus.publish(
      {
        type: MESSAGE_BUS.EVENTS_PUBLIC.THREED_CANCEL,
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );
  }

  private stopSubmitFormOnEnter() {
    const form: HTMLFormElement = document.getElementById(
      this.configService.getConfig().formId,
    ) as HTMLFormElement;

    if (!form) {
      return;
    }

    form.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }

  private initControlFrame$(): Observable<IConfig> {
    if (this.controlFrameLoader$) {
      return this.controlFrameLoader$;
    }

    this.controlFrameLoader$ = this.framesHub
      .waitForFrame(CONTROL_FRAME_IFRAME)
      .pipe(
        switchMap((controlFrame: string) => {
          if (controlFrame === CONTROL_FRAME_IFRAME) {
            return this.frameInitializer.request(
              this.configService.getConfig(),
            );
          }
          return EMPTY;
        }),
        tap(() => {
          this.merchantFields.init();
        }),
        shareReplay(1),
        takeUntil(this.destroy$),
      );

    return this.controlFrameLoader$;
  }

  private manageStorage(): void {
    this.storage.setItem(
      "merchantTranslations",
      JSON.stringify(this.configService.getConfig().translations),
    );
    this.storage.setItem(
      "locale",
      this.jwtDecoder.decode<IStJwtPayload>(this.configService.getConfig().jwt)
        .payload.locale || "en_GB",
    );
  }

  private displayLiveStatus(liveStatus: boolean): void {
    if (!liveStatus) {
      console.log(
        "%ctrust\n%cpayments\n%cThe Trust Payments ST.js library is currently configured for testing. After you have finished configuring your site and have tested thoroughly, please refer to the Trust Payments Help Center for go live configurations and checks.",
        "font-family: Tahoma, sans-serif; font-size: 6em; font-weight: bold; margin: 0 0 -10 0;",
        "font-family: Tahoma, sans-serif; font-size: 3em; font-weight: bold; margin: -28px 0 0 0;",
        "margin: 15px 0 28px 0; font-size: 13px; font-weight: regular; color: #e71b5a",
      );
    }
  }

  private watchForFrameUnload(): void {
    const controlFrameStatus = [false, false];

    const observer = new MutationObserver(() => {
      const controlFrame = document.getElementById(CONTROL_FRAME_IFRAME);

      controlFrameStatus.push(Boolean(controlFrame));
      controlFrameStatus.shift();

      const [isActivePreviousStatus, isActiveCurrentStatus] =
        controlFrameStatus;

      if (isActivePreviousStatus && !isActiveCurrentStatus) {
        this.destroy();
        observer.disconnect();
      }
    });

    observer.observe(document, {
      subtree: true,
      childList: true,
    });
  }

  private initCallbacks(config: IConfig): void {
    if (config.submitCallback) {
      this.submitCallback = config.submitCallback;
    }

    if (config.successCallback) {
      this.successCallback = config.successCallback;
    }

    if (config.errorCallback) {
      this.errorCallback = config.errorCallback;
    }

    if (config.cancelCallback) {
      this.cancelCallback = config.cancelCallback;
    }
  }

  private blockSubmitButton(): void {
    const form: HTMLFormElement = document.getElementById(
      this.configService.getConfig().formId,
    ) as HTMLFormElement;

    if (!form) {
      return;
    }

    const submitButton: HTMLInputElement | HTMLButtonElement =
      (document.getElementById(this.configService.getConfig().buttonId) as
        | HTMLInputElement
        | HTMLButtonElement) ||
      form.querySelector('button[type="submit"]') ||
      form.querySelector('input[type="submit"]');

    if (submitButton) {
      submitButton.classList.add("st-button-submit__disabled");
      submitButton.disabled = true;
    }
  }
}

export default (config: IConfig): ST => {
  return Container.get(ClientBootstrap).run(config);
};
