import { ApplePayMockModal } from "./ApplePayMockModal";

const address1 = {
  addressLines: ["49 Featherstone Street"],
  locality: "London",
  administrativeArea: "ENG",
  postalCode: "EC1Y 8SY",
  countryCode: "GB",
  country: "United Kingdom",
};

const address2 = {
  addressLines: ["1 Featherstone Street"],
  locality: "Paris",
  administrativeArea: "ABC",
  postalCode: "EC1Y 8SY",
  countryCode: "FR",
  country: "France",
};

const address3 = {
  addressLines: ["55 Featherstone Street"],
  locality: "London",
  administrativeArea: "ENG",
  postalCode: "EC1Y 8SY",
  countryCode: "GB",
  country: "United Kingdom",
};

export class ApplePayMockShippingContact {
  private modal: ApplePayMockModal;
  private modalContent: HTMLElement;
  private shippingContactComboBox: HTMLSelectElement | null = null;
  private selectedAddress: any = null;
  private onshippingcontactselected: (
    event: ApplePayJS.ApplePayShippingContactSelectedEvent,
  ) => void = () => {};

  constructor(modal: ApplePayMockModal) {
    this.modal = modal;
  }

  // Initialize UI elements for the shipping contact selection.
  public initShippingContactUI(
    defaultShippingContact: ApplePayJS.ApplePayPaymentContact,
    modalContent: HTMLElement,
  ): void {
    const parentShippingContainer = document.createElement("div");
    parentShippingContainer.className = "shipping-parent-container"; // Optional: Add class for styling
    parentShippingContainer.style.marginBottom = "0px";
    const label = document.createElement("label");
    label.textContent = "Shipping: ";

    this.shippingContactComboBox = document.createElement("select");

    // Add addresses to the combo-box
    [address1, address2, address3].forEach((address, index) => {
      const option = document.createElement("option");
      option.value = index.toString();
      option.textContent =
        address.addressLines.join(", ") + " - " + address.locality;
      this.shippingContactComboBox.appendChild(option);

      // Set the default shipping address
      if (
        defaultShippingContact &&
        defaultShippingContact.addressLines.join(", ") ===
          address.addressLines.join(", ")
      ) {
        this.shippingContactComboBox.selectedIndex = index;
        this.selectedAddress = address; // Set the default selected address
      }
    });

    // Append label and combo-box to the container
    parentShippingContainer.appendChild(label);
    parentShippingContainer.appendChild(this.shippingContactComboBox);

    // Insert the shipping container after the "Item: Fancy Widget" text
    const shippingContainerPlaceholder = modalContent.querySelector(
      "#shipping-container",
    );

    if (shippingContainerPlaceholder) {
      shippingContainerPlaceholder.appendChild(parentShippingContainer);
    }

    // Add event listener for address selection
    this.shippingContactComboBox.addEventListener("change", () => {
      this.onShippingContactSelected();
    });

    // Default configuration if no shipping contact is provided
    if (!this.selectedAddress) {
      this.selectedAddress = address1;
    }
    this.modalContent = modalContent;
  }

  public getSelectedAddress() {
    return this.selectedAddress;
  }

  public setShippingContactCallback(
    callback: (event: ApplePayJS.ApplePayShippingContactSelectedEvent) => void,
  ): void {
    this.onshippingcontactselected = callback;
  }

  // When a shipping contact is selected
  public onShippingContactSelected(): void {
    const selectedOptionIndex = this.shippingContactComboBox?.value;
    const addresses = [address1, address2, address3];
    this.selectedAddress = addresses[parseInt(selectedOptionIndex || "0", 10)];

    const event: ApplePayJS.ApplePayShippingContactSelectedEvent = {
      shippingContact: this.selectedAddress,
      bubbles: false,
      cancelBubble: false,
      cancelable: false,
      composed: false,
      currentTarget: undefined,
      defaultPrevented: false,
      eventPhase: 0,
      isTrusted: false,
      returnValue: false,
      srcElement: undefined,
      target: undefined,
      timeStamp: "",
      type: "",
      composedPath: function (): Node[] {
        throw new Error("Function not implemented.");
      },
      initEvent: function (
        type?: string,
        bubbles?: boolean,
        cancelable?: boolean,
      ): void {
        throw new Error("Function not implemented.");
      },
      preventDefault: function (): void {
        throw new Error("Function not implemented.");
      },
      stopImmediatePropagation: function (): void {
        throw new Error("Function not implemented.");
      },
      stopPropagation: function (): void {
        throw new Error("Function not implemented.");
      },
    };

    this.onshippingcontactselected(event);
  }

  // Handle shipping contact updates
  public handleShippingContactUpdate(
    updatedShippingContactData: any,
    price: string,
  ): void {
    const shippingChargesElement =
      this.modalContent.querySelector("#shipping-charges");

    if (shippingChargesElement) {
      shippingChargesElement.innerHTML =
        "<strong>Shipping Charges:</strong><br>";
    }

    if (updatedShippingContactData.newLineItems && shippingChargesElement) {
      updatedShippingContactData.newLineItems.forEach((item) => {
        const itemElement = document.createElement("p");
        itemElement.style.margin = "0";
        itemElement.textContent =
          parseFloat(item.amount) === 0
            ? `${item.label}: Free`
            : `${item.label}: £${item.amount}`;
        shippingChargesElement.appendChild(itemElement);
      });
    }

    if (updatedShippingContactData.newTotal) {
      // price
      this.modal.updateTotalAmount(
        updatedShippingContactData.newTotal.amount,
        price,
      );
    }

    this.validateShippingContact(updatedShippingContactData.status);
  }

  // Handle validation of shipping contact based on version
  public validateShippingContact(status: any): void {
    this.clearErrorMessages();
    if (typeof status === "number") {
      // Version 1/2 - Handle by checking status code
      this.handleErrorsForV2(status as number);
    } else if (status && Array.isArray(status)) {
      // Version 3+ - Handle errors array
      this.handleErrorsForV3(status as ApplePayJS.ApplePayError[]);
    }
  }

  // Version 1/2 specific error handling
  private handleErrorsForV2(status: number): void {
    if (status === 3) {
      this.displayErrorMessage("Invalid Address");
    }
  }

  // Version 3+ specific error handling
  private handleErrorsForV3(errors: ApplePayJS.ApplePayError[]): void {
    if (errors && errors.length > 0) {
      const shippingContactError = errors.find(
        (error) => error.code === "shippingContactInvalid",
      );
      if (shippingContactError) {
        this.displayErrorMessage(shippingContactError.message);
      }
    }
  }

  private displayErrorMessage(message: string): void {
    const errorElement = document.createElement("label");
    errorElement.id = "invalidAddress";
    errorElement.style.color = "red";
    errorElement.style.fontSize = "11px";
    errorElement.style.textAlign = "center";
    errorElement.textContent = message;
    this.modalContent.appendChild(errorElement);
  }

  private clearErrorMessages(): void {
    const errorLabel = this.modalContent.querySelector("#invalidAddress");
    if (errorLabel) {
      this.modalContent.removeChild(errorLabel);
    }
  }
}
