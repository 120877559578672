import { Observable, catchError, switchMap, throwError } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Inject, Service } from "typedi";
import { type IInternalsMonitor } from "../../../../../application/core/services/monitoring/IInternalsMonitor";
import { SRC_PROVIDER_TOKEN } from "../../../../../client/dependency-injection/InjectionTokens";
import { ENVIRONMENT } from "../../../../../environments/environment";
import { ConfigProvider } from "../../../../../shared/services/config-provider/ConfigProvider";
import { ISrc } from "../../ISrc";
import { ISrcProvider } from "../../ISrcProvider";
import { SrcName } from "../../SrcName";
import { ResourceLoadingTimeoutHandler } from "../../../../../shared/services/utils/ResourceLoadingTimeoutHandler";
import { MastercardSrcWrapper } from "./MastercardSrcWrapper";
import { DomScriptUtils } from "../../../../../application/core/shared/dom-methods/DomScriptUtils";

@Service({ id: SRC_PROVIDER_TOKEN, multiple: true })
export class MastercardSrcProvider implements ISrcProvider {
  private src: Observable<ISrc>;

  constructor(
    private configProvider: ConfigProvider,
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
    private resourceTimeoutHandler: ResourceLoadingTimeoutHandler,
  ) {
    this.src = this.configProvider.getConfig$().pipe(
      map((config) =>
        config.livestatus
          ? ENVIRONMENT.CLICK_TO_PAY.MASTERCARD.PROD
          : ENVIRONMENT.CLICK_TO_PAY.MASTERCARD.SANDBOX,
      ),
      switchMap((sdkConfig) =>
        DomScriptUtils.insertScript("head", {
          src: sdkConfig.SRC_SDK_URL,
        }).pipe(
          this.resourceTimeoutHandler.captureAndReportResourceLoadingTimeout(
            "Mastercard To Pay SDK script load timeout",
            ENVIRONMENT.CLICK_TO_PAY.SCRIPT_LOAD_TIMEOUT,
          ),
          catchError((error) => {
            this.internalMonitor.recordIssue(error);
            return throwError(() => error);
          }),
          map(() => new MastercardSrcWrapper(sdkConfig.SRC_INITIATOR_ID)),
        ),
      ),
      shareReplay(1),
    );
  }

  getSrcName(): SrcName {
    return SrcName.MASTERCARD;
  }

  getSrc(): Observable<ISrc> {
    return this.src;
  }
}
