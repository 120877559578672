import { interval, Observable } from "rxjs";
import { Service } from "typedi";
import { filter, first, map, switchMap } from "rxjs/operators";
import { DomMethods } from "../../../application/core/shared/dom-methods/DomMethods";
import { ENVIRONMENT } from "../../../environments/environment";
import { ICardinal } from "./ICardinal";
import { ICardinalProvider } from "./ICardinalProvider";
import { DomScriptUtils } from "../../../application/core/shared/dom-methods/DomScriptUtils";

@Service()
export class CardinalProvider implements ICardinalProvider {
  private static readonly scriptId = "cardinalCommerce";
  private static readonly containerId = "Cardinal-ElementContainer";

  getCardinal$(liveStatus: boolean): Observable<ICardinal> {
    const sdkAddress = liveStatus
      ? ENVIRONMENT.CARDINAL_COMMERCE.SONGBIRD_LIVE_URL
      : ENVIRONMENT.CARDINAL_COMMERCE.SONGBIRD_TEST_URL;

    const scriptOptions = {
      src: sdkAddress,
      id: CardinalProvider.scriptId,
    };

    const mobileViewStyles = `
      @media only screen and (max-width: 450px) {
        #${CardinalProvider.containerId} iframe#Cardinal-CCA-IFrame {
          width: 100vw;
        }
      }
    `;

    DomMethods.insertStyle(mobileViewStyles);

    return DomScriptUtils.insertScript("head", scriptOptions).pipe(
      switchMap(this.waitForCardinalInit.bind(this)),
    );
  }

  private waitForCardinalInit(): Observable<ICardinal> {
    return interval().pipe(
      // @ts-ignore
      map(() => window.Cardinal),
      filter(Boolean),
      first(),
    ) as Observable<ICardinal>;
  }
}
