import { IEnhancedApplePayPaymentRequest } from "../models/IEnhancedApplePayPaymentRequest";
import { ISelectableApplePayShippingMethod } from "../models/ISelectableApplePayShippingMethod";
import { ApplePayMockModal } from "./ApplePayMockModal";
import { MockedApplePaySession } from "./MockedApplePaySession";

export class ApplePayMockUiController {
  private modal: ApplePayMockModal;
  private paymentRequest: IEnhancedApplePayPaymentRequest;

  constructor(paymentRequest: IEnhancedApplePayPaymentRequest) {
    this.paymentRequest = paymentRequest;
  }

  public open(sessionMock: MockedApplePaySession): void {
    this.modal = new ApplePayMockModal(this.paymentRequest);
    this.modal.open();

    this.modal.setCancelCallback(() => this.close(sessionMock));

    if (sessionMock.onpaymentauthorized) {
      this.modal.setPaymentAuthorizedCallback(
        sessionMock.proceedPayment.bind(sessionMock),
      );
    }

    if (sessionMock.onshippingcontactselected) {
      this.modal.setShippingContactCallback(
        sessionMock.onshippingcontactselected,
      );
    }

    if (sessionMock.onshippingmethodselected) {
      this.modal.setShippingMethodSelectedCallback(
        sessionMock.onshippingmethodselected,
      );
    }
  }

  public updateShippingAmountChanges(
    status: unknown,
    newShippingMethods?: ISelectableApplePayShippingMethod[],
    newTotal?: ApplePayJS.ApplePayLineItem,
    newLineItems?: ApplePayJS.ApplePayLineItem[],
  ): void {
    this.modal.updateShippingAmountChanges(
      status,
      newShippingMethods,
      newTotal,
      newLineItems,
    );
  }

  public updateShippingMethodAmountChanges(
    status: unknown,
    newTotal?: unknown,
    newLineItems?: unknown,
  ) {
    this.modal.updateShippingMethodAmountChanges(
      status,
      newTotal,
      newLineItems,
    );
  }

  public unlockPaymentButton(): void {
    this.modal.unlock();
  }

  public close(applePaySession: MockedApplePaySession): void {
    applePaySession.oncancel(undefined);
    this.modal.close();
  }
}
