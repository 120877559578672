import { Service } from "typedi";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import {
  GOOGLE_PAYMENT_METHOD_WALLET_SOURCE,
  GOOGLE_PAYMENT_METHOD_NAME,
} from "../../../integrations/google-pay/models/IGooglePaymentMethod";
import { IGooglePaySessionPaymentsClient } from "../../../integrations/google-pay/models/IGooglePaySessionPaymentsClient";
import { IConfig } from "../../../shared/model/config/IConfig";
import { ConfigProvider } from "../../../shared/services/config-provider/ConfigProvider";
import { EventScope } from "../constants/EventScope";
import { GooglePayButtonAction } from "./GooglePayButtonAction";
import { IGooglePayButton } from "./IGooglePayButton";

@Service()
export class GooglePayButton implements IGooglePayButton {
  private googlePaySdk: IGooglePaySessionPaymentsClient;
  private config: IConfig;
  constructor(
    private configProvider: ConfigProvider,
    private messageBus: IMessageBus,
    private googlePayButtonAction: GooglePayButtonAction,
  ) {}
  createButton(
    config: IConfig,
    googlePaySdk: IGooglePaySessionPaymentsClient,
  ): Node {
    this.config = config;
    this.googlePaySdk = googlePaySdk;
    const {
      buttonColor,
      buttonType,
      buttonLocale,
      buttonRadius,
      buttonSizeMode,
    } = config.googlePay.buttonOptions;
    const button: Node = googlePaySdk.createButton({
      buttonColor,
      buttonType,
      buttonLocale,
      buttonRadius,
      buttonSizeMode,
      onClick: this.onBeforeGooglePaymentButtonClicked,
    });
    return button;
  }

  private onBeforeGooglePaymentButtonClicked = (): void => {
    const data: any = {
      /** @deprecated New clients should use ‘name’ instead, which is more consistent with other library callbacks. Note that the casing of the value will change */
      paymentOption: GOOGLE_PAYMENT_METHOD_WALLET_SOURCE,
      name: GOOGLE_PAYMENT_METHOD_NAME,
    };

    if (this.isPaymentPrecheckEnabled(this.configProvider.getConfig())) {
      data.paymentStart = this.onGooglePaymentButtonClicked;
    }

    this.messageBus.publish(
      {
        type: PUBLIC_EVENTS.PAYMENT_METHOD_PRE_CHECK,
        data,
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    if (!data.paymentStart) {
      this.onGooglePaymentButtonClicked();
    }
  };

  private onGooglePaymentButtonClicked = (): void => {
    this.messageBus.publish(
      {
        type: PUBLIC_EVENTS.PAYMENT_METHOD_STARTED,
        data: {
          name: GOOGLE_PAYMENT_METHOD_NAME,
        },
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );

    this.googlePayButtonAction.execute(this.config, this.googlePaySdk);
  };

  /**
   * A check to determine if the merchant has specified that
   * GooglePay should be configured to not auto-start.
   *
   * Auto-start gets disabled by adding ["GOOGLE"] to disabledAutoPaymentStart.
   *
   * @param config The APM config.
   *
   * @returns
   *  True if the merchant wants control over the payment
   * start action, rather than having the code execute it
   * directly
   */
  private isPaymentPrecheckEnabled(config: IConfig): boolean {
    return (config.disabledAutoPaymentStart ?? [])
      .map((paymentMethodName) => paymentMethodName.toLowerCase())
      .includes(GOOGLE_PAYMENT_METHOD_NAME.toLowerCase());
  }
}
