import { Service } from "typedi";
import { IApplePaySession } from "../../models/IApplePaySession";
import { IApplePayConfigObject } from "../config/IApplePayConfigObject";
import { ApplePayCancelAction } from "./ApplePayCancelAction";
import { ApplePayMerchantValidationAction } from "./ApplePayMerchantValidationAction";
import { ApplePayPaymentAuthorizationAction } from "./ApplePayPaymentAuthorizationAction";
import { IApplePayEventBinder } from "./IApplePayEventBinder";
import { ApplePayShippingMethodAction } from "./ApplePayShippingMethodAction";
import { ApplePayShippingContactAction } from "./ApplePayShippingContactAction";

@Service()
export class DefaultApplePayEventBinder implements IApplePayEventBinder {
  constructor(
    private validationAction: ApplePayMerchantValidationAction,
    private authorizationAction: ApplePayPaymentAuthorizationAction,
    private cancelAction: ApplePayCancelAction,
    private shippingMethodAction: ApplePayShippingMethodAction,
    private shippingContactAction: ApplePayShippingContactAction,
  ) {}

  bindAll(session: IApplePaySession, config: IApplePayConfigObject) {
    // Binds the behaviour for the 'onvalidatemerchant' callback from ApplePay.
    session.onvalidatemerchant = (e) =>
      this.validationAction.execute(session, config.validateMerchantRequest, e);

    // Binds the behaviour of the `onpaymentauthorized` callback from ApplePay.
    session.onpaymentauthorized = (data) => {
      this.authorizationAction.execute(
        session,
        config?.formId,
        data,
        config?.applePayConfig?.callbacks,
      );
    };

    if (config?.applePayConfig?.callbacks?.onShippingMethodSelected) {
      // Binds the behaviour of the `onshippingmethodselected` callback from ApplePay.
      session.onshippingmethodselected = (data) => {
        this.shippingMethodAction.execute(
          session,
          config.applePayVersion,
          data,
          config.applePayConfig.callbacks,
        );
      };
    }

    if (config?.applePayConfig?.callbacks?.onShippingContactSelected) {
      // Binds the behaviour of the `onshippingmethodselected` callback from ApplePay.
      session.onshippingcontactselected = (data) => {
        this.shippingContactAction.execute(
          session,
          config.applePayVersion,
          data,
          config.applePayConfig.callbacks,
        );
      };
    }

    // Binds the behaviour for the 'oncancel' callback from ApplePay.
    session.oncancel = (e) => this.cancelAction.execute();
  }
}
