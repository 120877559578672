import { Observable, map, first } from "rxjs";
import Container, { Service } from "typedi";
import { ofType } from "../../../../shared/services/message-bus/operators/ofType";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { IMessageBus } from "../../shared/message-bus/IMessageBus";
import { EventScope } from "../../models/constants/EventScope";
import { MESSAGE_BUS_TOKEN } from "../../../../shared/dependency-injection/InjectionTokens";
import { IScriptOriginTag } from "./interfaces/IScriptOriginTag";
import { ScriptOriginTagger } from "./ScriptOriginTagger";
import { IScriptOriginStream } from "./IScriptOriginStream";

@Service()
export class ScriptOriginStream implements IScriptOriginStream {
  constructor(private messageBus: IMessageBus) {
    this.messageBus = Container.get(MESSAGE_BUS_TOKEN);
  }

  push(url: string | null) {
    this.messageBus.publish<string>(
      {
        type: PUBLIC_EVENTS.SCRIPT_ORIGIN_LOCATED,
        data: url,
      },
      EventScope.EXPOSE_TO_MERCHANT,
    );
  }

  getLibraryOrigin$(): Observable<IScriptOriginTag> {
    return this.messageBus.pipe(
      ofType(PUBLIC_EVENTS.SCRIPT_ORIGIN_LOCATED),
      map((e) => new ScriptOriginTagger().getTag(e.data)),
      first(),
    );
  }
}
